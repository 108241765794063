import { useEffect, useState } from 'react';
import { Fields, Field, Submit } from 'modules/react-fields-context';
import { apiFetch } from '_dash/components/util';
import { deleteCookie, setCookie, getCookie } from 'modules/cookies';
import ClassNames from 'classnames';
import Spinner from 'modules/react-spinner';
import RequestResetPassword from './RequestResetPassword';
import { Button, FormSignUpWrap } from '_dash/styleComponents/CommonStyle';
import { decrypt, encrypt } from 'modules/crypto';

export default function TabLogin({ btnLoginTxt = 'Login In To My Account', type = '' }) {
    const [initialValues, setInitialValues] = useState({});
    const [resetPassword, setResetPassword] = useState(false);

    useEffect(() => {
        if (getCookie('ul') && getCookie('ul')) {
            try {
                decrypt(getCookie('ul')).then((userLogin) => {
                    decrypt(getCookie('up')).then((userPassword) => {
                        setInitialValues({ userLogin, userPassword, remember_me: 1 });
                    });
                });
            } catch (error) {
                deleteCookie('ul');
                deleteCookie('up');
            }
        }
    }, []);

    // handle active account
    const classMsg = new URLSearchParams(window.location.search).get('classMsg');
    const msg = new URLSearchParams(window.location.search).get('msg');

    // const [state, setstate] = React.useState(initialState)

    const handleLogin = (data, doneSubmit) => {
        if (window.devMode) window.console.log('recite', window.Recite); // @debug
        data['action'] = 'api.front.doLogin';
        data['type'] = type;
        apiFetch(data).then((r) => {
            if (r.status === 1) {
                //remember me functionality
                if (data.remember_me === 1) {
                    encrypt(data.userLogin).then((encrypted) => setCookie('ul', encrypted));
                    encrypt(data.userPassword).then((encrypted) => setCookie('up', encrypted));
                } else {
                    deleteCookie('ul');
                    deleteCookie('up');
                }
                const { pathname } = window.location;
                // do page reload becase need to get all redux data again after login
                // go to dash for employer user if is not already in it

                switch (r.data.type) {
                    case 'employer':
                        if (pathname.substring(0, 6) !== '/dash/') {
                            window.location.pathname = '/dash/home/';
                        } else if (pathname.substring(0, 7) === '/login/') {
                            window.location.pathname = '/dash/';
                        } else if (window.location.search.length > 0) {
                            window.location.search = ''; //do reload and remove query paramas
                        } else {
                            window.location.reload();
                        }
                        break;

                    case 'candidate':
                        if (data.type === 'hse_session_expired') {
                            window.location.reload();
                        } else if (pathname.substring(0, 6) !== '/dash/') {
                            window.location.pathname = '/dash/home/';
                        } else if (pathname.substring(0, 7) === '/login/') {
                            window.location.pathname = '/dash/';
                        } else if (window.location.search.length > 0) {
                            window.location.search = ''; //do reload and remove query paramas
                        } else {
                            window.location.reload();
                        }
                        break;

                    default:
                        window.location.reload();
                        break;
                }

                /* if (r.data.type === 'employer' && pathname.substring(0, 6) !== '/dash/') {
                    window.location.pathname = '/dash/home/';
                } else if (pathname.substring(0, 7) === '/login/') {
                    window.location.pathname = '/dash/';
                } else if (window.location.search.length > 0) {
                    window.location.search = ''; //do reload and remove query paramas
                } else {
                    window.location.reload();
                } */
                // dispatch({ type: ActionConstants.UPDATE_USERDATA, payload: JSON.parse(r.data) });
                // close();
            } else {
                loginRestricted({ loginStatus: r.type, msg: r.msg, userLogin: data.userLogin });
                doneSubmit({}, r.fields);
            }
        });
    };
    if (resetPassword) return <RequestResetPassword notActive={notActive} goBack={() => setResetPassword(false)} />;

    return (
        <div className="tab-pane active">
            {/* <h2>Login</h2> */}
            <Fields data-action="login" id="formLogin" onSubmit={handleLogin} initialValues={initialValues}>
                <label className={'alert alert-' + classMsg}>{msg}</label>
                <FormSignUpWrap>
                    <div className="form-row">
                        <Field
                            name="userLogin"
                            placeholder="Enter Email"
                            className="col-12 emailBox"
                            schema={{
                                type: 'text',
                                rules: { email: '', required: '' },
                            }}
                        />
                        <Field
                            name="userPassword"
                            type="password"
                            placeholder="Enter Password"
                            className="col-12 passwordBox"
                            schema={{
                                type: 'text',
                                hideInfoPasswordStrength: true,
                                rules: { required: '' },
                            }}
                        />
                    </div>
                </FormSignUpWrap>

                <div className="bottomOption">
                    <Field
                        name="remember_me"
                        schema={{
                            type: 'checkbox',
                            label: 'Remember me',
                        }}
                    />
                    <button id="resetPsw" type="button" onClick={() => setResetPassword(true)}>
                        Forgot Password
                    </button>
                </div>

                <Submit
                    render={(isLoading, isSaved, submit) => {
                        // when use `remember_me` then login data will be auto saved, so need to login
                        // if (isSaved) {
                        // 	return (
                        // 		<button className="btn" type="button">
                        // 			Update login details bebore submit again
                        // 		</button>
                        // 	);
                        // } else {
                        return (
                            <p className="mb-0 text-center">
                                <Button onClick={submit} disabled={isLoading} className={ClassNames({ 'btn-isLoading': isLoading })}>
                                    {isLoading && <Spinner />}
                                    {/* {window.Recite === undefined && isLoading && <Spinner />} */}
                                    {btnLoginTxt}
                                </Button>
                            </p>
                        );
                        // }
                    }}
                />
            </Fields>
        </div>
    );
}

export function notActive(userLogin) {
    window.swal({
        text: (
            <p>
                The account is not activated! Resend an activation link to <b>{userLogin}</b>.
            </p>
        ),
        type: 'warning',
        showCancelButton: true,
        onConfirm: () => {
            // e.currentTarget.disabled = true;
            apiFetch({ action: 'api.front.Login.sendActivationLink', email: userLogin }).then((r) => {
                if (r.status === 1) {
                    window.swal({
                        type: 'info',
                        text: (
                            <p>
                                {r.userType === 'employer' ? (
                                    <>We have received your request and one of our team will be with you shortly to activate and setup your account.</>
                                ) : (
                                    <>
                                        Check your email address <b>{userLogin}</b> to activate your account.
                                    </>
                                )}
                            </p>
                        ),
                    });
                } else {
                    window.swal({ type: 'warning', text: 'Login not found!' });
                }
                // 	dispatch({ type: 'COMPANY_RECEIVE_USER_ROLES', payload: data });
                // 	setEditRoles(false);
                // close();
            });
        },
    });
}

export function loginRestricted({ userLogin, loginStatus, msg }) {
    switch (loginStatus) {
        case 'inactiv':
            notActive(userLogin);
            break;
        case 'forceResetPassword':
            window.swal({
                text: msg ? msg : 'You must first reset your password before you can login.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Reset Password',
                onConfirm: () => {
                    apiFetch({ action: 'api.front.Login.resetPasswordRequest', userLogin: userLogin }).then((r) => {
                        window.swal(r);
                    });
                },
            });
            break;
        default:
            break;
    }
}
