export const RECEIVE_PATHS = 'RECEIVE_PATHS';

export const INITIAL_DATALOAD = 'INITIAL_DATALOAD';
export const RECEIVE_COMPANY = 'RECEIVE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_BY_KEY = 'UPDATE_COMPANY_BY_KEY';
export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const UPDATE_COMPANY_JBSETTINGS = 'UPDATE_COMPANY_JBSETTINGS';

export const RECEIVE_COMPANY_GALLERY = 'RECEIVE_COMPANY_GALLERY';
export const GALLERY_ADD_ITEM = 'GALLERY_ADD_ITEM';
export const GALLERY_REMOVE_ITEM = 'GALLERY_REMOVE_ITEM';

export const UPLOAD_BANNER = 'UPLOAD_BANNER';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';

// hr_albums_media stuff
export const MEDIA_UPLOAD = 'MEDIA_UPLOAD';
export const MEDIA_REMOVE = 'MEDIA_REMOVE';

export const RECEIVE_COMPANY_ALBUMS = 'RECEIVE_COMPANY_ALBUMS';
export const COMPANY_ALBUM_ADD = 'COMPANY_ALBUM_ADD';
export const COMPANY_ALBUM_DELETE = 'COMPANY_ALBUM_DELETE';
export const COMPANY_ALBUM_RENAME = 'COMPANY_ALBUM_RENAME';
export const COMPANY_ALBUM_MEDIA_ADD = 'COMPANY_ALBUM_MEDIA_ADD';
export const COMPANY_ALBUM_MEDIA_DELETE = 'COMPANY_ALBUM_MEDIA_DELETE';
export const COMPANY_ALBUM_MEDIA_EDIT = 'COMPANY_ALBUM_MEDIA_EDIT';
export const COMPANY_ALBUM_MEDIA_REORDER = 'COMPANY_ALBUM_MEDIA_REORDER';

export const ALBUM_ADD_MEDIA = 'ALBUM_ADD_MEDIA';
export const ALBUM_REMOVE_MEDIA = 'ALBUM_REMOVE_MEDIA';

export const RECEIVE_COMPANY_CULTURE = 'RECEIVE_COMPANY_CULTURE';

export const RECEIVE_COMPANY_BENEFITS = 'RECEIVE_COMPANY_BENEFITS';
export const UPDATE_BENEFITS = 'UPDATE_BENEFITS';

export const CULTURE_ADD_MEDIA = 'CULTURE_ADD_MEDIA';
export const CULTURE_REMOVE_MEDIA = 'CULTURE_REMOVE_MEDIA';
export const CULTURE_UPDATE = 'CULTURE_UPDATE';
export const CULTURE_CAREER_ADD = 'CULTURE_CAREER_ADD';
export const CULTURE_CAREER_REMOVE = 'CULTURE_CAREER_REMOVE';
export const CULTURE_CAREER_UPDATE = 'CULTURE_CAREER_UPDATE';

export const CULTURE_STATS_ADD = 'CULTURE_STATS_ADD';
export const CULTURE_STATS_UPDATE = 'CULTURE_STATS_UPDATE';
export const CULTURE_STATS_REMOVE = 'CULTURE_STATS_REMOVE';
export const CULTURE_STATS_ADD_REFRESH = 'CULTURE_STATS_ADD_REFRESH';
export const CULTURE_STATS_REMOVE_REFRESH = 'CULTURE_STATS_REMOVE_REFRESH';

export const RECEIVE_COMPANY_INCENTIVES = 'RECEIVE_COMPANY_INCENTIVES';
export const RECEIVE_POPULATE_JOB = 'RECEIVE_POPULATE_JOB';

export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const RECEIVE_MESSAGECOUNTS = 'RECEIVE_MESSAGECOUNTS';
export const UPDATE_MESSAGECOUNTS = 'UPDATE_MESSAGECOUNTS';
export const RECEIVE_NAVDATA = 'RECEIVE_NAVDATA';
export const RECEIVE_USERDATA = 'RECEIVE_USERDATA';
export const UPDATE_USERDATA = 'UPDATE_USERDATA';

export const FUTUREROLES_RECEIVE = 'FUTUREROLES_RECEIVE';
export const FUTUREROLES_UPDATE = 'FUTUREROLES_UPDATE';

export const RECEIVE_COMPANY_JOBS = 'RECEIVE_COMPANY_JOBS';
export const RECEIVE_COMPANY_USERS = 'RECEIVE_COMPANY_USERS';
export const RECEIVE_COMPANY_USER_ROLES = 'RECEIVE_COMPANY_USER_ROLES';

export const RECEIVE_CVDATA = 'RECEIVE_CVDATA';
export const UPDATE_CVDATA = 'UPDATE_CVDATA';

export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';

// FRONT
export const FRONT_MENU_RECEIVE = 'FRONT_MENU_RECEIVE';

export const HIERARCHY_USERS_ADD = 'HIERARCHY_USERS_ADD';
