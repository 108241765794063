import produce from 'immer';

export const employerAutoMessages = (state = [], action) =>
    produce(state, (draft) => {
        // eslint-disable-next-line default-case
        switch (action.type) {
            case 'EMPLOYER_AUTO_MESSAGES_RECEIVED':
                Object.assign(draft, action['payload']);
                break;
            case 'EMPLOYER_AUTO_MESSAGES_UPDATED':
                draft[draft.findIndex((e) => e.id === action.payload.id)] = action.payload;
                break;
            case 'EMPLOYER_AUTO_MESSAGES_ADDED':
                draft.unshift(action.payload);
                break;
            case 'EMPLOYER_AUTO_MESSAGES_DELETED':
                draft.splice(
                    draft.findIndex((e) => e.id === action.payload),
                    1
                );
                break;
        }
    });
