import React from 'react';
import { IconFirefox, IconChrome, IconSafari, IconEdge } from '_dash/components/Icon';
import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';

import 'scss/application.scss';

const BrowserNotSupportedSt = styled.div`
    .bg-top {
        padding: 30px;
        img {
            max-width: 220px;
        }
    }
    .content {
        position: fixed;
        top: 20%;
        right: 0;
        left: 0;
        width: 780px;
        max-width: 100%;
        margin: 0 auto 0;
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 20px;
        box-shadow: 0 0 9px 5px rgba(0, 0, 0, 0.1);
        text-align: center;
        z-index: 10;
        @media (max-width: 992px) {
            width: 85%;
        }
        @media (max-width: 768px) {
            margin-top: -10%;
        }
        p {
            font-weight: 400;
            font-size: 17px;
            color: ${variables.colorInner};
            font-weight: 500;
        }
        ul {
            list-style: none;
            display: flex;
            flex-flow: row wrap;
            padding-left: 0;
            max-width: 600px;
            margin: 50px auto;
            li {
                padding: 0;
                max-width: 25%;
                flex: 0 0 25%;
                @media (max-width: 500px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    margin-bottom: 35px;
                }
            }
            img {
                max-width: 60px;
            }
            svg {
                max-width: 70px;
                max-height: 60px;
            }
            span {
                display: block;
                margin-top: 10px;
                color: ${variables.colorPrimary};
                font-weight: 500;
            }
        }
    }
`;

const BgShape = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    img {
        width: 100%;
        @media (max-width: 1199px) {
            height: 100%;
        }
    }
`;
const BannerSection = styled.section`
    height: 650px;
    background-image: -o-linear-gradient(350deg, ${variables.colorPrimary} 0%, ${variables.colorSecondary} 100%);
    background-image: linear-gradient(100deg, ${variables.colorPrimary} 0%, ${variables.colorSecondary} 100%);
    position: relative;
`;
const CircleShape = styled.div`
    position: absolute;
    top: -20%;
    left: -13%;
    @media (max-width: 1440px) {
        top: -40%;
        left: -20%;
    }
`;
export default function IENotSupportedPopup() {
    return (
        <BrowserNotSupportedSt>
            {window.location.pathname !== '/hse-form/' && (
                <BannerSection>
                    <div className="bg-top">
                        <img src={`${process.env.REACT_APP_SITE_URL}src/images/layout/logoWhite.png`} alt="Logo" />
                    </div>
                    <CircleShape>
                        <img src={`${process.env.REACT_APP_SITE_URL}src/media/circle-shape.png`} alt="circle" />
                    </CircleShape>

                    <BgShape className="bgShape_IE">
                        <img src={`${process.env.REACT_APP_SITE_URL}src/media/shape-bg-banner_blue_15.png`} alt="banner" />
                    </BgShape>
                </BannerSection>
            )}

            <div className="content">
                <h1>Browser not supported</h1>
                <p>You're using a web browser we don't support.</p>
                <p>Please use one of these options to improve your experience.</p>
                <ul>
                    <li>
                        <IconFirefox />
                        <span>Firefox</span>
                    </li>
                    <li className="ch">
                        {/* <img src={`${process.env.REACT_APP_SITE_URL}src/media/browser/chrome.png`} alt="Chrome" /> */}
                        <IconChrome />
                        <span>Chrome</span>
                    </li>
                    <li>
                        <IconEdge />
                        {/* <img src={`${process.env.REACT_APP_SITE_URL}src/media/browser/edge.png`} alt="Edge" />*/}
                        <span>Edge</span>
                    </li>
                    <li>
                        <IconSafari />
                        {/* <img src={`${process.env.REACT_APP_SITE_URL}src/media/browser/safari.png`} alt="Safari" /> */}
                        <span>Safari</span>
                    </li>
                </ul>
            </div>
        </BrowserNotSupportedSt>
    );
}
