import React from 'react';
import { Button, FormSignUpWrap } from '_dash/styleComponents/CommonStyle';
import { connect } from 'react-redux';
import { Fields, Field, Submit } from 'modules/react-fields-context';
import { apiFetch } from '_dash/components/util';
import PropTypes from 'prop-types';
import Dropdown from 'modules/react-dropdown';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { LoginContext } from '_dash/Contexts';
import Spinner from 'modules/react-spinner';

import * as ActionConstants from '_dash/redux/constants';

function FormRegister({ dispatch, isBuildCv, isCompany, history, isTryForFree = 'false' }) {
    const context = React.useContext(LoginContext);
    const handleRegisterJobSeeker = (data, doneSubmit) => {
        data['action'] = 'api.front.doRegisterJobSeeker';
        // apiFetch(data).then(r => (r !== undefined ? window.location.reload() : doneSubmit({},r)));
        apiFetch(data).then((r) => {
            if (r.status === 1) {
                dispatch({ type: ActionConstants.UPDATE_USERDATA, payload: { id: r.id, type: 'candidate', name: data.userFirstName + ' ' + data.userLastName } });
                if (context.dispatch) context.dispatch({ type: 'HIDE_LOGIN' });
                window.swal({
                    type: 'success',
                    text: <div dangerouslySetInnerHTML={{ __html: r.html }} />,
                    onConfirm: () => (window.location.pathname = '/dash/'),
                    showCancelButton: false,
                    confirmButtonText: 'ok',
                });
            } else {
                doneSubmit({}, r.fields);
            }
        });
    };

    const handleRegisterCompany = (data, doneSubmit) => {
        data['action'] = 'api.front.doRegisterCompany';
        apiFetch(data).then((r) => {
            if (r.status === 1) {
                // dispatch({ type: ActionConstants.UPDATE_USERDATA, payload: { id: r.id, type: 'employer', permissionIds: [] } });
                if (context.dispatch) context.dispatch({ type: 'HIDE_LOGIN' });
                // history.push('/dash/');
                window.swal({
                    type: 'success',
                    // text: 'Thank you for registering your company with REZOOMO.COM, we have sent you a confirmation email. Please follow the link to activate your account',
                    text: (
                        <>
                            Thank you for registering your company with <a href="https://www.rezoomo.com">REZOOMO.COM</a>, We have received your details and one of our team will be
                            with you shortly to activate and setup your account.
                        </>
                    ),
                    // onConfirm: () => window.location.reload(),
                    confirmButtonText: 'ok',
                    showCancelButton: false,
                });
                // just need to reset one field , and all fields get reset
                doneSubmit({ userFirstName: '' });
            } else {
                doneSubmit({}, r.fields);
            }
        });
    };

    const CommonFieldsSignUp = () => (
        <FormSignUpWrap>
            {/* <label className="msgError text-danger" /> */}
            <div className="form-row">
                <Field
                    name="userFirstName"
                    placeholder="First Name"
                    className="col-6 capitalize"
                    schema={{
                        type: 'text',
                        rules: { required: '' },
                    }}
                />

                <Field
                    name="userLastName"
                    placeholder="Last Name"
                    className="col-6 capitalize"
                    schema={{
                        type: 'text',
                        rules: { required: '' },
                    }}
                />
            </div>

            {isCompany && (
                <>
                    <Field
                        name="companyName"
                        placeholder="Company Name"
                        className="capitalize"
                        schema={{
                            type: 'text',
                            rules: { required: '' },
                        }}
                    />

                    {/* {isTryForFree !== true && (
                        <Field
                            name="companyPhone"
                            placeholder="Telephone Number"
                            className="capitalize"
                            schema={{
                                type: 'text',
                                rules: { required: '' },
                            }}
                        />
                    )} */}
                    {isTryForFree === true && (
                        <Field
                            name="companyNoEmployees"
                            placeholder="Number of employees"
                            schema={{
                                type: 'text',
                            }}
                        />
                    )}
                </>
            )}

            <Field
                name="userEmail"
                placeholder={isCompany ? 'Work Email' : 'Email'}
                schema={{
                    type: 'text',
                    rules: { email: '', required: '' },
                    maxLength: '80',
                }}
            />
            <div className="input-group strengthIndicator passwordInput-box">
                <Field
                    name="userPassword"
                    type="password"
                    placeholder="Password"
                    className="userPassword"
                    schema={{
                        type: 'text',
                        rules: { required: '', equalToField: 'userPassword2', strongPassword: true },
                    }}
                />
                <Dropdown>
                    {(handleToggle, isOpen, refOpen) => (
                        <div className={ClassNames('input-info ', { show: isOpen })}>
                            <i className="far fa-info-circle" onClick={handleToggle} />
                            {isOpen && (
                                <div ref={refOpen} className="dropdown-menu tooltip-dropdown-menu pos-left">
                                    <span className="arrow" />
                                    <p>To make your password more secure:</p>
                                    <ul>
                                        <li> - Use at least 8 characters</li>
                                        <li> - Include at least a number or symbol ( e.g. @ !)</li>
                                        <li> - Mix lower and uppercase</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </Dropdown>
            </div>

            <Field
                name="userPassword2"
                type="password"
                placeholder="Confirm Password"
                schema={{
                    type: 'text',
                    rules: { required: '' },
                }}
            />

            <Field
                name="checkTerms"
                schema={{
                    type: 'checkbox',
                    rules: { required: '' },
                    label: (
                        <span>
                            I agree to the <a href="/terms-of-use">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
                        </span>
                    ),
                }}
            />

            <Submit
                render={(isLoading, isSaved, submit) => {
                    // if (isSaved) {
                    //     return (
                    //         <p className="mb-0">
                    //             <Button>Sign Up Now</Button>
                    //         </p>
                    //     );
                    // } else {
                    return (
                        <p className="cta-form text-center">
                            <Button onClick={submit} disabled={isLoading} className={ClassNames({ 'btn-isLoading': isLoading })}>
                                {isLoading && <Spinner />}
                                {isBuildCv === true ? 'Build my Digital CV' : isTryForFree === true ? 'Start My Free Trial' : 'Sign Up Now'}
                            </Button>

                            {/* {isTryForFree === true && <a href="/login">Already have an account?</a>} */}
                            {isTryForFree === true && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (context.dispatch) context.dispatch({ type: 'CHANGE_TAB', payload: { tab: 'tab-login' } });
                                        if (context.dispatch) context.dispatch({ type: 'SHOW_LOGIN' });
                                    }}
                                >
                                    <span>Already have an account?</span>
                                </button>
                            )}
                        </p>
                    );
                    // }
                }}
            />
        </FormSignUpWrap>
    );

    if (isCompany) {
        const initVals = {
            // userEmail: context.loginContext.tryEmail,
        };
        return (
            <>
                {isTryForFree !== true && <h2>Employers Sign Up</h2>}
                {/* {isTryForFree === true && <p>A free trial entitles employers to post 1 live job for 14 days.</p>} */}
                <Fields initialValues={initVals} data-action="login" id="registerCompanyForm" onSubmit={handleRegisterCompany}>
                    <CommonFieldsSignUp />
                </Fields>
            </>
        );
    } else {
        return (
            <Fields data-action="login" onSubmit={handleRegisterJobSeeker} className={isBuildCv === true ? 'formBuildCV' : ''}>
                <CommonFieldsSignUp />
            </Fields>
        );
    }
}

FormRegister.propTypes = {
    dispatch: PropTypes.func,
    isBuildCv: PropTypes.bool,
    isCompany: PropTypes.bool.isRequired,
    isTryForFree: PropTypes.bool,
};

export default withRouter(connect()(FormRegister));
