import produce from 'immer';

export const companyUsersReducer = (state = [], action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'COMPANY_USERS_RECEIVED':
                Object.assign(draft, action['payload']);
                break;
            case 'COMPANY_USER_UPDATED':
                draft[draft.findIndex((user) => user.id === action.payload.id)] = action.payload;
                break;
            case 'COMPANY_USER_ADDED':
                draft.unshift(action.payload);
                break;
            case 'COMPANY_USER_DELETED':
                draft.splice(
                    draft.findIndex((user) => user.id === action.payload),
                    1
                );
                break;
            // no default
        }
    });
