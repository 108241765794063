import React from 'react';

export default function Spinner() {
    return (
        <span className="spinner-loading">
            <span className="dot1" />
            <span className="dot2" />
            <span className="dot3" />
        </span>
    );
}
