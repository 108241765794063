export function setCookie(cname, cValue, exDays = 1000) {
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cValue + '; ' + expires + '; path=/; samesite=strict;secure';
}

export function getCookie(cname, cookiesStr) {
    const name = cname + '=';
    const ca = cookiesStr ? cookiesStr : document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
    }
    return '';
}

export function deleteCookie(cname) {
    setCookie(cname, '', -1000);
}
