import React from 'react';
import PropTypes from 'prop-types';

import { FieldsContext } from './context';

export default class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false, //wait for context to be avaible and in cdm we get it
        };
    }

    componentDidMount() {
        let stateUpdate = { isMounted: true };
        this.context.useCustomSubmit();
        this.setState(stateUpdate);
    }

    render() {
        if (this.state.isMounted) {
            // const isNewRecord = JSON.stringify(this.context.initialValues) == '{}';
            return this.props.render(
                this.context.isSubmitting || this.context.isValidating,
                this.context.isSaved,
                this.context.handleSubmit,
                this.context.initialValues,
                this.context.values
            );
        } else {
            return null;
        }
    }
}

Submit.propTypes = {
    render: PropTypes.func.isRequired, //ex: render={ (isLoading,isSaved,isNewRecord) => {} }
};

Submit.contextType = FieldsContext;
