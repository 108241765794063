import * as ActionConstants from '_dash/redux/constants';
import produce from 'immer';

export const companyAlbumsReducer = (state = [], action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionConstants.RECEIVE_COMPANY_ALBUMS:
                Object.assign(draft, action['payload']);
                break;
            case ActionConstants.COMPANY_ALBUM_ADD:
                draft.push(action.payload);
                break;
            case ActionConstants.COMPANY_ALBUM_DELETE:
                draft.splice(
                    draft.findIndex((item) => item.id === action.payload.id),
                    1
                );
                break;
            case ActionConstants.COMPANY_ALBUM_RENAME: {
                const index = draft.findIndex((item) => item.id === action.payload.id);
                draft[index] = Object.assign(draft[index], action.payload);
                break;
            }
            case ActionConstants.COMPANY_ALBUM_MEDIA_ADD: {
                const index = draft.findIndex((item) => item.id === action.payload.albumId);
                draft[index].medias.push(action.payload.data);
                break;
            }
            case ActionConstants.COMPANY_ALBUM_MEDIA_DELETE: {
                const index = draft.findIndex((item) => item.id === action.payload.albumId);
                const indexMedia = draft[index].medias.findIndex((item) => item.id === action.payload.id);
                draft[index].medias.splice(indexMedia, 1);
                break;
            }
            case ActionConstants.COMPANY_ALBUM_MEDIA_REORDER: {
                const index = draft.findIndex((item) => item.id === action.payload.albumId);
                draft[index].medias = action.payload.medias;
                break;
            }
            //no default
        }
    });
