// Assume the key and iv are Uint8Array types for AES-CBC which requires a 256-bit key (32 bytes) and a 128-bit iv (16 bytes).
const keyBytes = new Uint8Array([
    29, 61, 16, 52, 90, 12, 10, 18, 39, 58, 23, 5, 87, 52, 43, 76,
    45, 62, 13, 9, 28, 34, 11, 88, 95, 26, 49, 33, 78, 60, 41, 7
]);

const ivBytes = new Uint8Array([
    12, 74, 56, 78, 10, 12, 34, 56, 78, 90, 12, 34, 56, 78, 90, 12
]);

// Convert text to ArrayBuffer
function str2ab(str) {
    const encoder = new TextEncoder();
    return encoder.encode(str);
}

// Convert ArrayBuffer to string
function ab2str(buf) {
    const decoder = new TextDecoder();
    return decoder.decode(buf);
}

// Convert ArrayBuffer to hex string
function buf2hex(buffer) {
    return Array.prototype.map.call(new Uint8Array(buffer), x => x.toString(16).padStart(2, '0')).join('');
}

// Convert hex string to ArrayBuffer
function hex2buf(hex) {
    return new Uint8Array(hex.match(/[\da-f]{2}/gi).map(h => parseInt(h, 16)));
}

// Import the secret key
async function importKey(rawKey) {
    return window.crypto.subtle.importKey(
        'raw',
        rawKey,
        { name: 'AES-CBC', length: 256 }, // Specify algorithm details
        false,
        ['encrypt', 'decrypt']
    );
}

export async function encrypt(text) {
    const key = await importKey(keyBytes);
    const encrypted = await window.crypto.subtle.encrypt(
        { name: 'AES-CBC', iv: ivBytes },
        key,
        str2ab(text)
    );
    return buf2hex(encrypted);
}

export async function decrypt(encryptedHex) {
    const key = await importKey(keyBytes);
    const decrypted = await window.crypto.subtle.decrypt(
        { name: 'AES-CBC', iv: ivBytes },
        key,
        hex2buf(encryptedHex)
    );
    return ab2str(decrypted);
}

// Example usage:
// encrypt('Hello World').then((encrypted) => {
//     console.log('Encrypted:', encrypted);
//     decrypt(encrypted).then((decrypted) => {
//         console.log('Decrypted:', decrypted);
//     });
// });
