import { useEffect, useState } from 'react';
import { apiFetch, preLoading } from '../util';
import { DocumentsContext } from '_dash/Contexts';

export default function DocumentsProvider({ children }) {
    const [docs, setDocs] = useState();
    const [activeFolderId, setActiveFolderId] = useState();
    const [addedFolderId, setAddedFolderId] = useState();

    const fetchDocs = async () =>
        apiFetch({ action: 'api.dash.employer.company.document.get' }).then((r) => {
            setDocs(r);
            if (activeFolderId === undefined) setActiveFolderId(r[0].id);
        });
    useEffect(() => {
        fetchDocs();
    }, []);

    if (!docs) return preLoading();
    return (
        <DocumentsContext.Provider value={{ fetchDocs, docs, setDocs, activeFolderId, setActiveFolderId, addedFolderId, setAddedFolderId }}>{children}</DocumentsContext.Provider>
    );
}
