import { applyMiddleware, createStore } from 'redux';
import differ from 'deep-diff';
import rootReducer from '../reducers/index.js';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

if (window.devMode) window.console.log(2, 'f.createStore');

/**
 * Logs actions and catch wrong actions when diff is undefined.
 */
const logger = (store) => (next) => (action) => {
    if (typeof action !== 'function') {
        const prevState = store.getState();
        const result = next(action);
        const nextState = store.getState();
        const diff = differ(prevState, nextState);
        if (diff === undefined) {
            //TODO: to be send to server this errors
            if (window.devMode) {
                window.console.group(action.type);
                window.console.error('redux diff undefined');
                window.console.error(
                    'this happen when action.type is not found in reducer or because of the bad implementation the same action repeat twice or you receive wrong data from the server'
                );
                window.console.log('check if is something wrong with the action object:', action);
                window.console.groupEnd();
                // window.swal({ type: 'warning', text: `action '${action.type}' didn't made any change` });
            }
        }
        if (window.devMode) window.console.info('dispatching', action);
        return result;
    } else {
        return next(action);
    }
};

export default function store(initialData = {}) {
    return createStore(rootReducer, initialData, composeWithDevTools(applyMiddleware(thunkMiddleware, logger)));
}
