// import { apiFetch } from '../../components/util';

export const REQUEST_DEPARTMENTS = 'REQUEST_DEPARTMENTS';
export const RECEIVE_DEPARTMENTS = 'RECEIVE_DEPARTMENTS';
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';

export const addDepartment = (data) => ({ type: ADD_DEPARTMENT, payload: data });
export const updateDepartment = (data) => ({ type: UPDATE_DEPARTMENT, payload: data });
export const removeDepartment = (data) => ({ type: REMOVE_DEPARTMENT, payload: data });
export const receiveDepartments = (data) => ({ type: RECEIVE_DEPARTMENTS, payload: data });

// members inside of departmends
export const ADD_MEMBER = 'ADD_MEMBER';
export const addMember = (data) => ({ type: ADD_MEMBER, payload: data });
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const removeMember = (data) => ({ type: REMOVE_MEMBER, payload: data });
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const updateMember = (data) => ({ type: UPDATE_MEMBER, payload: data });
export const UPLOAD_MEMBER_IMAGE = 'UPLOAD_MEMBER_IMAGE';
export const uploadMemberImage = (data) => ({ type: UPLOAD_MEMBER_IMAGE, payload: data });

/*export function addDepartment() {
  return dispatch => {
		apiFetch( {action:'api.dash.employer.department.add'}).then( (obj) => {
			dispatch(({ type: ADD_DEPARTMENT, payload: obj }));
		});
  };
}*/

/*export function fetchIfNeeded() {
  return (dispatch, getState) => {
    if(!getState().departments){
      apiFetch( {action:'api.dash.employer.department.onMount'}).then( (data) => {
        //dispatch(receiveDepartments(data));
        dispatch(({ type: RECEIVE_DEPARTMENTS, payload: data }));
        // add department if empty
     //    if(!Object.keys(getState().departments).length) {
					// apiFetch( {action:'api.dash.employer.department.add'}).then( (id) => {
					// 	dispatch(({ type: ADD_DEPARTMENT, payload: id }));
					// });
     //    }
      });
    }
  };
}*/

/*export function addDepartmentIfEmpty() {
  return (dispatch, getState) => {
    if(!Object.keys(getState().departments).length) {
			apiFetch( {action:'api.dash.employer.department.add'}).then( (id) => {
				dispatch(({ type: ADD_DEPARTMENT, payload: id }));
			});
    }
  };
}*/
