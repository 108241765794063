import React from 'react';
import PropTypes from 'prop-types';

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: props.isOpen,
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openRef = React.createRef(); // will be used to close when click outside this ref
    }

    componentDidMount() {
        if (this.props.isOpen) document.addEventListener('click', this.closeMenu, { capture: true });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen }, () => {
                if (this.props.isOpen === true) {
                    document.addEventListener('click', this.closeMenu, { capture: true });
                } else {
                    document.removeEventListener('click', this.closeMenu, { capture: true });
                }
            });
        }
    }

    handleToggle(event) {
        if (event !== undefined) event.preventDefault();

        this.setState(
            (prevState) => ({
                isOpen: !prevState.isOpen,
            }),
            () => {
                if (this.state.isOpen === true) {
                    document.addEventListener('click', this.closeMenu, { capture: true });
                } else {
                    document.removeEventListener('click', this.closeMenu, { capture: true });
                }
            }
        );
    }

    closeMenu(event) {
        if (this.openRef.current !== null) {
            // close only when click outside and the element exist in dom, to avoid the case when delete a element and don't want to get auto close the dropdown because that element don't exist anymore in dom
            if (!this.openRef.current.contains(event.target) && document.body.contains(event.target)) {
                this.setState({ isOpen: false }, () => {
                    document.removeEventListener('click', this.closeMenu);
                });
            }
        }
    }

    render() {
        return this.props.children(this.handleToggle, this.state.isOpen, this.openRef);
    }
}

Dropdown.propTypes = {
    // children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.element, PropTypes.bool, PropTypes.func]),
    children: PropTypes.func,
    isOpen: PropTypes.bool,
};
Dropdown.defaultProps = {
    isOpen: false,
};
