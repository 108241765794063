import { cloneDeep } from 'lodash';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { DocumentsContext } from '_dash/Contexts';
import { preLoading } from '../util';
import { FetchNextApi } from 'modules/next-fetch';

export default function DocumentsFile({ e, isEditMode }) {
    const [isLoading, setISLoading] = useState(false);
    const { fetchDocs, setDocs, docs } = useContext(DocumentsContext);
    const siteUrl = useSelector((state) => state.paths.siteUrl);
    let iconFormat;

    const handleDownload = async () => {
        setISLoading(true);

        const fetchNextApi = new FetchNextApi('company/document?' + new URLSearchParams({
            id: e.id
        }), {
            method: 'get'
        });
        
        fetchNextApi
            .downloadFile(e.name)
            .catch(error => {
                console.log(error);
            });

        setISLoading(false);
    };

    const handleDelete = async () => {
        window.swal({
            type: 'warning',
            title: 'Are you sure you want to delete this document?',
            confirmButtonText: 'Delete',
            onConfirm: async (close) => {
                
                let formData = new FormData();

                formData.append('action', 'api.dash.employer.company.document.delete');
                formData.append('id', e.id);

                const fetchNextApi = new FetchNextApi('company/document', {
                    method: 'delete',
                    body: formData
                });
                
                fetchNextApi
                    .fetch()
                    .then(async response => {                    
                        if (response.success) {
                            await fetchDocs();

                            window.toast({ type: 'info', text: 'document deleted' });

                            close();
                        }
                    });
            },
        });
    };

    switch (e.format) {
        case 'pdf':
            iconFormat = <i className="fal fa-file-pdf text-danger" />;
            break;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'cfb': // It's commonly associated with Microsoft's file formats like older versions of Excel, Word, and PowerPoint, which used a container format for storing files
            iconFormat = <i className="fal fa-file-word text-info" />;
            break;
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'ods':
            iconFormat = <i className="fal fa-file-excel text-success" />;
            break;
        case 'ppt':
        case 'pptx':
        case 'odp':
            iconFormat = <i className="fal fa-file-powerpoint text-warning" />;
            break;
        //no default
    }
    if (isLoading) return preLoading();
    return (
        <div className="col-6 col-md-3 col-lg-4 col-xl-3 file-box">
            <div className="inner">
                <div className="doc-body bg-grey">
                    {isEditMode === false && (
                        <span
                            className={'selectItem ' + (e.isSelect ? ' checked' : '')}
                            onClick={() => {
                                e.isSelect = !e.isSelect;
                                setDocs(cloneDeep(docs));
                            }}
                        />
                    )}
                    {iconFormat}

                    <div className="cta">
                        {isEditMode && (
                            <span className="delete tooltip" onClick={handleDelete} data-tooltip={'Delete'}>
                                <i className="fas fa-trash-alt"></i>
                            </span>
                        )}
                        <span className="download tooltip" onClick={handleDownload} data-tooltip={'Download'}>
                            <i className="fas fa-download" />
                        </span>
                    </div>
                </div>
                <div className="doc-footer">
                    <div className="name">
                        <p className="mb-0 has-tooltip-top has-tooltip-arrow" data-tooltip={e.name}>
                            <span>{e.name}</span>
                        </p>
                        <span className="size">{(e.size / 1024 / 1024).toFixed(3)} MB</span>
                    </div>
                    <p className="addedBy mb-0">{e.addedBy}</p>
                    <p className="date mb-0">{e.date}</p>
                </div>
            </div>
        </div>
    );
}
