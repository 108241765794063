import React from 'react';
import PropTypes from 'prop-types';

import TextEditor from './TextEditor';

export default class TextEditorWrapper extends React.Component {
    constructor(props) {
        super(props);

        const { text, showEmptyEditor } = props;
        this.state = {
            showEditor: text.length > 0 || showEmptyEditor,
        };

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text.length !== this.props.text.length) {
            const { text, showEmptyEditor } = this.props;
            this.setState({ showEditor: text.length > 0 || showEmptyEditor });
        }
        /* if (this.props.text.length === 0 && prevState.showEditor && !this.props.showEmptyEditor) {
      this.setState({showEditor: false});
    } */
    }

    handleCancel() {
        const { text, showEmptyEditor } = this.props;
        this.setState({ showEditor: text.length > 0 || showEmptyEditor });
    }

    render() {
        const { wrapperClassName, wrapperStyle, header, title, text, description, showEmptyEditor, allowCancelOnEmpty } = this.props;
        const { showEditor } = this.state;

        return (
            <div className={wrapperClassName} style={wrapperStyle}>
                {header !== undefined && header}

                {header === undefined && title !== undefined && title.length > 0 && <h2 className="pt-0">{title}</h2>}

                {!showEditor && text.length === 0 && !showEmptyEditor && (
                    <p className="icon-add inline" onClick={() => this.setState({ showEditor: true })}>
                        <span className="icon">
                            <i className="far fa-plus" />
                        </span>
                        <span className="txt">{description}</span>
                    </p>
                )}

                {showEditor && (
                    <div className="editor-field">
                        <TextEditor
                            content={text}
                            changeReadOnly={this.props.mode === 'edit'}
                            editorOpen={!text.length}
                            allowCancelOnEmpty={allowCancelOnEmpty}
                            doUpdate={this.props.doUpdate}
                            doCancel={this.handleCancel}
                            editableChange={this.props.editorStateChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}
TextEditorWrapper.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    doUpdate: PropTypes.func,
    wrapperClassName: PropTypes.string,
    header: PropTypes.func,
    description: PropTypes.string,
    wrapperStyle: PropTypes.object,
    showEmptyEditor: PropTypes.bool,
    allowCancelOnEmpty: PropTypes.bool,
    editorStateChange: PropTypes.func,
    mode: PropTypes.oneOf(['edit', 'view']),
};
TextEditorWrapper.defaultProps = {
    text: '',
    description: 'Add Description',
    wrapperStyle: { position: 'relative' },
    showEmptyEditor: false,
    allowCancelOnEmpty: true,
    mode: 'edit',
};
