import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

class ModalPortal extends React.Component {
    render() {
        return ReactDOM.createPortal(this.props.children, document.getElementById('modal-root'));
    }
}
ModalPortal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.element, PropTypes.bool]),
};

export default function Modal({ className, trigger, children }) {
    const [isOpenState, setIsOpenState] = useState(trigger ? false : true);

    const handleToggle = () => {
        
        const modalEl = document.getElementById('modal-root');
        if (modalEl.childElementCount === 2) {
            //console.log('modalEl1=', modalEl.childElementCount, 'om1=', isOpenState);
            if (isOpenState) {
                //when modal is closed and is last open modal
                document.body.style.position = '';
                document.body.style.width = 'auto';
                document.body.style.height = 'auto';
                //document.body.style.overflow = 'unset';
            }
        } else {
            //console.log('modalEl2=', modalEl.childElementCount, 'om2=', isOpenState);
            if (!isOpenState) {
                document.body.style.position = 'fixed';
                document.body.style.width = '100%';
                document.body.style.height = '100%';
                //document.body.style.overflow = 'hidden';
            }
        }
        //at end because modalEl.childElementCount can differ when the state is updated before
        setIsOpenState(!isOpenState);
    };

    const closeByEsc = (e) => {
        if (e.keyCode === 27) {
            setIsOpenState(false);
            document.body.style.position = '';
            document.body.style.width = 'auto';
            document.body.style.height = 'auto';
        }
    };
    React.useEffect(() => {
        document.addEventListener('keydown', closeByEsc, true);
        // const modalEl = document.getElementById('modal-root');
        // console.log('modalEl', modalEl.childElementCount, 'c= ', modalEl.getElementsByClassName('#modal-root .modal').length);
        // if (modalEl !== null && modalEl.childElementCount > 1) {
        //     if (isOpenState) document.body.style.overflow = 'hidden';
        //     else document.body.style.overflow = 'unset';
        // }
        return () => {
            document.removeEventListener('keydown', closeByEsc, true);
        };
    }, []);

    return (
        <React.Fragment>
            {trigger && trigger(handleToggle)}
            {isOpenState && (
                <ModalPortal>
                    <div className="modal fade show" style={{ display: 'block', background: 'rgb(0,0,0,0.7)' }} role="dialog">
                        <div className={'modal-dialog ' + className}>{trigger || typeof children === 'function' ? children(handleToggle) : children}</div>
                    </div>
                </ModalPortal>
            )}
        </React.Fragment>
    );
}

Modal.propTypes = {
    // children: PropTypes.func,
    trigger: PropTypes.func,
    className: PropTypes.string,
};
