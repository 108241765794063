import * as ActionConstants from '_dash/redux/constants';

const benefitFields = ['benefitText', 'benefitIncentivesText', 'incentiveItems', 'benefitFlexibleWorkText'];

export const companyBenefitsReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_COMPANY_BENEFITS:
            return action.payload;
        case ActionConstants.UPDATE_BENEFITS: {
            let newState = Object.assign({}, state);
            benefitFields.forEach((item) => {
                if (typeof action.payload[item] !== 'undefined') {
                    newState[item] = action.payload[item];
                }
            });
            return newState;
        }

        default: {
            return state;
        }
    }
};
