import styled, { createGlobalStyle, keyframes } from 'styled-components/macro';
import variables from 'scss/base/_variables.module.scss';
import { MenuWrapper, HeaderSt } from '_dash/styleComponents/HeaderStyle';
import { LoginWrap } from '_dash/styleComponents/login/Login';

export const Container = styled.div`
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 12%;
    padding-right: 12%;
    @media (max-width: 1400px) {
        padding-left: 8%;
        padding-right: 8%;
    }
    @media (max-width: 1200px) {
        padding-left: 35px;
        padding-right: 35px;
    }
    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const GlobalFont = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
`;
export const themeSite = {
    fontFamily: 'Poppins',
    fontSize: '14px',
};

export const GlobalStyle = createGlobalStyle`

    * {
        margin: 0px;
        padding: 0px;
        outline: none;
    }
    html {
        height: 100%;
    }
    body {
        font-family: ${themeSite.fontFamily};
        position: relative;
        font-size: ${themeSite.fontSize};
        font-weight: 400;
        line-height: 1.5;
        color: ${variables.colorBase};
        min-height: 100%;
        overflow-x: hidden;
        background-color: transparent;
        &.openLoginBox {
            overflow-y: hidden;
            ${LoginWrap} {
                transform: translateY(0);
            }
             ${HeaderSt} {
                background-color: #fff;
            }
        }
        @media (max-width: 991px) {
            &.activeMenu {
                &:before {
                    transition: all 1s ease-in-out;
                    background-color: rgba(0, 0, 0, 0.5);
                    content: "";
                    height: 100%;
                    width: 100%;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 1000;
                }
                ${MenuWrapper} {
                    display: block;
                }
            }
        }
    }
    a {
        cursor: pointer;
	    color: ${variables.colorPrimary};
        &:hover {
            text-decoration: none;
            color: ${variables.colorSecondary};
        }
        &:focus {
            outline: none;
        }
        &:link, &:visited {
            text-decoration: none;
        } 
    }
   h1, h2, h3, h4, h5, h6 {
        color:  ${variables.colorHeading};
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 15px;
        position: relative;
        display: block;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    h4, h5 {
        font-size: 14px;
    }
    .row:after,
    .row:before {
        content: normal;
    }
    button {
        &:focus {
            outline: none;
        }
    }
`;

export const PageLoader = styled.div`
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    svg {
        display: none;
    }
    .blobs {
        /* filter: url(#goo);
        -webkit-filter: url(#goo); */
        width: 300px;
        height: 300px;
        position: relative;
        overflow: hidden;
        border-radius: 70px;
        transform-style: preserve-3d;
    }
    .blob-center {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        position: absolute;
        background: ${variables.colorPrimary};
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        transform-origin: left top;
        transform: scale(0.9) translate(-50%, -50%);
        animation: blob-grow linear 3.4s infinite;
        border-radius: 50%;
        box-shadow: 0 -10px 40px -5px ${variables.colorPrimary};
    }
    .blob {
        position: absolute;
        background: ${variables.colorPrimary};
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        animation: blobs ease-out 3.4s infinite;
        transform: scale(0.9) translate(-50%, -50%);
        transform-origin: center top;
        opacity: 0;
        &:nth-child(1) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        &:nth-child(2) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
    }
`;

export const Button = styled.button.attrs((props) => ({
    padding: props.padding || '7px 37px',
    margin: props.margin,
}))`
    color: #fff;
    background-color: ${variables.colorPrimary};
    border: 1px solid ${variables.colorPrimary};
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
    border-radius: 25px;
    display: inline-block;
    transition: all ease 0.3s;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
        background-color: #fff;
        color: ${variables.colorPrimary};
        box-shadow: 0 20px 30px 0 rgba(79, 35, 35, 0.15);
    }
    @media (max-width: 768px) {
        padding: 7px 25px;
        line-height: 26px;
    }
`;
export const ButtonOrange = styled(Button)`
    background-color: ${variables.orange};
    border-color: ${variables.orange};
    &:hover {
        background-color: #fff;
        border-color: #fff;
        color: ${variables.orange};
    }
`;

export const ButtonTransparent = styled(Button)`
    background-color: #fff;
    border-color: ${variables.colorPrimary};
    color: ${variables.colorPrimary};
    box-shadow: 0 20px 30px 0 rgba(79, 35, 35, 0.15);
    &:hover {
        background-color: ${variables.colorPrimary};
        border-color: #fff;
        color: #fff;
        box-shadow: none;
    }
`;

export const SectionTitle = styled.div.attrs((props) => ({
    textAlign: props.textAlign || 'center',
}))`
    margin-bottom: 30px;
    text-align: ${(props) => props.textAlign};
    @media (max-width: 575px) {
        margin-bottom: 15px;
    }
    .sub-title {
        font-size: 16px;
        font-weight: 500;
        color: ${variables.colorPrimary};
        letter-spacing: 3px;
    }
    .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4;
        @media (max-width: 575px) {
            font-size: 24px;
        }
    }
    p {
        margin-bottom: 20px;
        font-size: 18px;
        color: ${variables.colorPrimary};
        font-weight: 400;
    }
`;
export const SectionTitleOnBg = styled(SectionTitle)`
    .title,
    .sub-title {
        color: #fff;
    }
`;

export const FormSignUpWrap = styled.div.attrs((props) => ({
    marginTop: props.marginTop || '30px',
}))`
    margin-top: ${(props) => props.marginTop};
    /* .form-control {
        background: ${variables.colorInput};
        padding: 15px 25px;
        height: auto;
        border-radius: 8px;
        @media (max-width: 768px) {
            padding: 8px 15px;
        }
    } */
    .input-group.strengthIndicator .input-info {
        right: 5px;
        @media (max-width: 768px) {
            top: 9px;
        }
    }
`;

export const ShapeBg = styled.div.attrs((props) => ({
    maxWidth: props.maxWidth,
    left: props.left,
    right: props.right,
    top: props.top,
}))`
    position: absolute;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    z-index: -1;
    max-width: ${(props) => props.maxWidth};

    /* @media (max-width: 991px) {
        left: 0;
        right: 0;
    } */
`;
export const ShapeBgAngle = styled.div`
    background-image: -o-linear-gradient(350deg, ${variables.colorSecondary} 0%, ${variables.colorPrimary} 100%);
    background-image: linear-gradient(100deg, ${variables.colorSecondary} 0%, ${variables.colorPrimary} 100%);
    max-height: 700px;
    min-height: 700px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    @media (max-width: 768px) {
        min-height: 500px;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 200px solid transparent;
        border-left: 1900px solid #fff;
        border-bottom: 0px solid transparent;
    }
`;

export const ShapeShadow = styled.div.attrs((props) => ({
    maxWidth: props.maxWidth || '73%',
    bottom: props.bottom || '-40px',
}))`
    border-radius: 10px;
    background-color: white;
    opacity: 0.4;

    /* box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2); */
    height: 100px;
    max-width: ${(props) => props.maxWidth};
    position: absolute;
    bottom: ${(props) => props.bottom};
    width: 100%;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767.98px) {
        height: 60px;
    }
`;
export const CircleShape = styled.div.attrs((props) => ({
    left: props.left || 'auto',
    right: props.right || 'auto',
    top: props.top || 'auto',
    bottom: props.bottom || 'auto',
}))`
    position: absolute;
    z-index: 1;
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    svg {
        max-width: 950px;
        max-height: 950px;
    }
`;

export const PageTitle = styled.div`
    text-align: center;
    max-width: 770px;
    margin: 0 auto;
    @media (max-width: 992px) {
        max-width: 90%;
    }
    h1 {
        font-size: 50px;
        font-weight: 800;
        position: relative;
        z-index: 2;
        margin: 30px auto 15px;
        @media (max-width: 992px) {
            font-size: 40px;
        }
    }
    p {
        font-size: 20px;
    }
`;

export const PillarRL = styled.div.attrs((props) => ({
    padding: props.padding,
}))`
    padding: ${(props) => props.padding};
    position: relative;
    @media (max-width: 1200px) {
        padding: 50px 0;
        ${ShapeBg} {
            max-width: 800px;
        }
        &.pillar-revers {
            padding-top: 10px;
            ${ShapeBg} {
                max-width: 400px;
            }
        }
    }
    @media (max-width: 992px) {
        padding: 0 0 30px;

        &.pillar-revers {
            padding-top: 25px;
            ${ShapeBg} {
                max-width: 375px;
            }
        }
    }
    ${SectionTitle} {
        text-align: left;
    }
`;

export const ColumnRL = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    flex: 1;
    justify-content: center;
    ${PillarRL}.pillar-revers & {
        order: 2;
    }
    @media (max-width: 992px) {
        flex-flow: row wrap;
        ${PillarRL}.pillar-revers & {
            order: 1;
        }
    }
    ${SectionTitle} {
        margin-bottom: 15px;
        @media (max-width: 1200px) {
            margin-bottom: 0;
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        position: relative;
        &.column-img {
            @media (max-width: 992px) {
                margin: 0 auto;
                padding: 0 50px;
            }
            @media (max-width: 768px) {
                margin: 0 auto;
                padding: 0 30px 0 0;
            }
        }
        &.column-content {
            padding-right: 50px;
            @media (max-width: 1400px) {
                padding: 0 25px 0 0;
            }
            @media (max-width: 1200px) {
                padding: 0 0 0 95px;
            }
            @media (max-width: 1024px) {
                padding: 0 0 0 65px;
            }
            @media (max-width: 992px) {
                padding: 0 15px;
            }
            ${PillarRL}.pillar-revers & {
                padding-left: 50px;
                padding-right: 0;
                @media (max-width: 1400px) {
                    padding: 0 0 0 25px;
                }
                @media (max-width: 1200px) {
                    padding: 0 80px 0 0;
                }
                @media (max-width: 1024px) {
                    padding: 0 60px 0 0;
                }
                @media (max-width: 992px) {
                    padding: 0 15px;
                }
            }
        }
        img {
            box-shadow: 0 25px 40px 0 rgba(79, 35, 35, 0.2);
            border-radius: 8px;
        }
        .image-one {
            max-width: 720px;
            position: relative;
            left: -17%;
            @media (min-width: 2000px) {
                left: 3%;
            }
            @media (max-width: 1700px) {
                max-width: 620px;
                left: -15%;
            }
            @media (max-width: 1400px) {
                left: -11%;
            }
            @media (max-width: 1200px) {
                left: 0;
            }
            @media (max-width: 992px) {
                max-width: 570px;
                margin: 0 auto;
            }
            ${PillarRL}.pillar-revers & {
                right: -25%;
                left: auto;
                @media (max-width: 1700px) {
                    right: -30%;
                }
                @media (max-width: 1500px) {
                    right: -22%;
                }
                @media (max-width: 1400px) {
                    right: -15%;
                }
                @media (max-width: 1200px) {
                    right: 0;
                }
            }
        }
        .image-two {
            position: absolute;
            max-width: 720px;
            ${PillarRL}.pillar-revers & {
                top: auto;
                transform: translateY(0);
                bottom: -20%;
                left: 11%;
                @media (max-width: 1790px) {
                    bottom: -14%;
                }
                @media (max-width: 1400px) {
                    left: 0;
                    right: 0;
                    bottom: -20%;
                }
                @media (max-width: 1200px) {
                    max-width: 100%;
                    right: auto;
                    left: -10%;
                }
                @media (max-width: 992px) {
                    left: 18%;
                    right: 0;
                    bottom: -25px;
                    max-width: 525px;
                    margin: 0 auto;
                }
            }
            &.image-mobile {
                right: 10%;
                top: 50%;
                transform: translateY(-50%);
                max-width: 240px;
                @media (max-width: 1500px) {
                    right: 5%;
                }
                @media (max-width: 1400px) {
                    max-width: 200px;
                    right: 0;
                }
                @media (max-width: 1200px) {
                    right: -15%;
                }
                @media (max-width: 1024px) {
                    max-width: 185px;
                }
                @media (max-width: 992px) {
                    max-width: 200px;
                    right: 3%;
                }
                @media (max-width: 575px) {
                    max-width: 150px;
                    right: 0;
                }
                @media (max-width: 400px) {
                    max-width: 135px;
                }
            }
        }
        ${Button} {
            @media (max-width: 768px) {
                margin-top: 5px;
            }
        }
    }
    ul {
        li {
            font-size: 16px;
            font-weight: 500;
            padding-left: 30px;
            position: relative;
            &:after {
                content: '\f00c';
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: 'Font Awesome 5 Pro';
                padding-right: 5px;
                font-size: 16px;
                position: absolute;
                left: 0;
                top: 1px;
                color: ${variables.colorPrimary};
            }
        }
    }
`;
export const ColumnRLRevers = styled(ColumnRL)`
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
    ${PillarRL}.pillar-revers & {
        order: 1;
    }
    @media (max-width: 1200px) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    @media (max-width: 992px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding-top: 60px;
        ${PillarRL}.pillar-revers & {
            order: 2;
        }
    }
`;

export const BannerTryForFree = styled.div`
    position: relative;
    .caption {
        padding-top: 13%;
        height: 750px;
        margin-bottom: 200px;
        background-image: -o-linear-gradient(350deg, ${variables.colorPrimary} 0%, ${variables.colorSecondary} 100%);
        background-image: linear-gradient(100deg, ${variables.colorPrimary} 0%, ${variables.colorSecondary} 100%);
        position: relative;
        @media (max-width: 1370px) {
            height: 640px;
        }
        @media (max-width: 991px) {
            height: auto;
            padding: 90px 50px 0;
            margin-bottom: 60px;
        }
        @media (max-width: 576px) {
            padding: 90px 10px 0;
        }

        .row {
            position: relative;
            z-index: 2;
            a {
                color: #fff;
                &:hover {
                    text-decoration: underline;
                }
            }
            form {
                a {
                    color: ${variables.colorPrimary};
                }
            }
        }
    }
    .content-text {
        padding-top: 60px;
        @media (max-width: 991px) {
            padding-top: 20px;
        }
        .inner {
            max-width: 500px;
        }
        h1 {
            font-size: 52px;
            color: #fff;
            margin-bottom: 35px;
            @media (max-width: 1200px) {
                font-size: 36px;
            }
            @media (max-width: 576px) {
                font-size: 30px;
            }
        }
        p {
            color: #fff;
            font-size: 20px;
        }
    }
    .col-text-desc {
        .inner {
            margin-top: -150px;
            margin-bottom: 50px;
            max-width: 500px;
            padding-right: 30px;
            @media (max-width: 991px) {
                max-width: 100%;
                margin-top: 0;
                padding-right: 30px;
            }
        }
        ul {
            li {
                padding-left: 30px;
                position: relative;
                font-size: 18px;
                /* @media (max-width: 768px) {
                    font-size: 14px;
                } */
            }
            i {
                margin-right: 10px;
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
    }
    .bottom-shape {
        position: absolute;
        left: 0;
        bottom: -40px;
        z-index: 1;
        width: 100%;
        @media (max-width: 991px) {
            bottom: -15px;
        }
        img {
            width: 100%;
        }
    }
`;

export const FormTryFree = styled.div`
    position: relative;
    z-index: 100;
    .inner {
        background-color: #fff;
        box-shadow: 20px 15px 25px 0px rgba(79, 35, 35, 0.2);
        border-radius: 8px;
        color: #333e49;
        padding: 50px;
        max-width: 550px;
        margin-left: auto;
        @media (max-width: 1200px) {
            padding: 25px;
        }
        @media (max-width: 991px) {
            max-width: 100%;
        }
        @media (max-width: 575px) {
            padding: 20px 15px;
        }
    }
    .input-group .input-info {
        top: 15px;
        @media (max-width: 768px) {
            top: 9px;
        }
    }
    .cta-form {
        text-align: center;
        button {
            display: block;
            margin: 20px auto 0;
            span {
                color: ${variables.colorBase};
                font-weight: 500;
                &:hover {
                    color: ${variables.colorPrimary};
                }
            }
        }
    }
`;

export const SearchJobWrap = styled.div`
    form {
        padding: 15px 140px 15px 25px;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
        position: relative;
        @media (max-width: 767.98px) {
            padding: 20px 10%;
            border-radius: 8px;
        }
        @media (max-width: 575.98px) {
            padding: 15px 30px;
        }
    }
    .form-group {
        padding: 0 3px;
    }
    .form-control {
        padding: 8px 5px;
        //height: auto;
    }
    .input-group {
        i {
            position: absolute;
            top: 14px;
            left: 10px;
            color: ${variables.colorPrimary};
            z-index: 11;
        }
        .form-control {
            padding-left: 20px;
            border-radius: 8px !important;
        }
    }
    .search-btn {
        @media (min-width: 768px) {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        padding: 10px 15px;
        background-color: ${variables.colorPrimary};
        color: #fff;
        border-radius: 25px;
        border: 1px solid ${variables.colorPrimary};
        font-weight: 500;
        line-height: 14px;
        transition: all ease 0.3s;
        @media (max-width: 767.98px) {
            padding: 10px 25px;
            margin-left: 5px;
        }
        i {
            margin-right: 5px;
            font-size: 16px;
        }
        &:hover {
            background-color: #fff;
            color: ${variables.colorPrimary};
        }
    }
`;

/// !!!!!!!!TODO move in FindNOResult.sjx when change that component
export const NoJobsFindStyle = styled.div`
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid ${variables.colorBorder};
    padding: 30px 15px;
    h1 {
        font-weight: 600;
        margin-top: 0;
        @media only screen and (max-width: 1400px) {
            font-size: 18px;
        }
        &.title {
            padding-right: 40px;
        }
    }
    h2 {
        color: ${variables.colorPrimary};
        font-weight: 600;
    }
    .btn {
        min-width: 250px;
        margin: 20px auto 0;
        color: ${variables.colorPrimary};
        @media (max-width: 1200px) {
            margin-left: 10px;
            margin-right: 10px;
        }
        &:hover {
            color: #fff;
        }
        &.btn-buildCV {
            color: #fff;
            background-color: ${variables.colorPrimary};
            &:hover {
                background-color: transparent;
                color: ${variables.colorPrimary};
                &:before {
                    background-image: url(${process.env.REACT_APP_SITE_URL}src/images/layout/icons/build_a_cvBlue.png);
                }
            }
        }
        @media only screen and (max-width: 1400px) {
            min-width: 200px;
        }
    }
    .fa-times {
        &:hover {
            color: red;
        }
    }
    .tab-content {
        padding: 0 20px;
    }
    .block--cvBuilderTabs {
        .close {
            top: 25px;
            right: 15px;
            &:hover {
                color: red;
            }
        }
    }
    .howWorks-wrap .item-howWorks h2 {
        color: ${variables.colorBase};
        font-weight: 400;
    }
`;

// export const DropdownHoverWrap = styled.ul`
//     position: relative;
// `;

// this will replace : <ul className="container-show animated zoomIn">
export const ContainerShow = styled.ul.attrs((props) => ({
    width: props.width || '300px',
    top: props.top || '10px',
    left: props.left || 'auto',
    right: props.right || '50%',
    bottom: props.bottom || 'auto',
    maxHeight: props.maxHeight || '160px',
    // transform: props.translate || 'translate(0, 0)',
}))`
    position: absolute;
    top: 10px; //100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 18px 2px rgba(130, 130, 130, 0.2);
    border: 1px solid rgba(132, 146, 175, 0.15);
    font-size: 13px;
    padding: 5px;
    overflow: auto;
    z-index: 10;

    left: ${(props) => props.left};
    right: ${(props) => props.right};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    width: ${(props) => props.width};
    max-height: ${(props) => props.maxHeight};
    //transform: ${(props) => props.translate};
    li {
        padding: 3px;
        color: ${variables.colorInner};
        margin-bottom: 3px;
        border-radius: 40px;
        border: 1px solid rgba(45, 157, 183, 0.2);
        background-color: #f4fafb;
        color: #999;
        display: block;
        overflow: hidden;
        .box {
            position: relative;
            padding-left: 28px;
            display: block;
            font-size: 13px;
            margin-bottom: 0;
            @media (max-width: 991.98px) {
                font-size: 12px;
            }
        }
        .name {
            color: ${variables.colorBase};
        }
        .txt {
            font-size: 13px;
        }
    }
    img.img-circle,
    .noImg {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        max-width: 25px;
        font-size: 12px;
    }
    .noImg {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        color: #fff;
        text-align: center;
        line-height: 25px;
        font-size: 12px;
    }
`;

export const PeriodLabelTitle = styled.label`
    //.periodTitle
    position: absolute;
    top: -30px;
    left: 0;
    @media (max-width: 1199.98px) {
        left: 15px;
    }
    @media (max-width: 991.98px) {
        position: relative;
        top: 0;
        left: 0;
    }
`;

const animationBackground = keyframes`
    0% {
        background-color: rgba(0,0,0,0);
    }
    100% {
        background-color: rgba(0,0,0,0.2);
    }
`;
export const BackgroundWrapSt = styled.div`
    animation: 300ms ease 0s 1 normal both running ${animationBackground};
    inset: 0px;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 1000;
`;

export const CustomTooltipSt = styled.button.attrs((props) => ({
    padding: props.padding || '35px 15px 15px 25px',
}))`
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: ${variables.colorBase};
    font-weight: 500;
    position: relative;
    padding: ${(props) => props.padding};
    text-align: left;
    .item {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 10px;
        .count-views {
            color: ${variables.orange}; //#2d9db7;
            order: 1;
            max-width: 100%;
            flex: 0 0 100%;
        }
        .count-app {
            color: ${variables.colorPrimary};
            order: 2;
            max-width: 100%;
            flex: 0 0 100%;
        }
        &.item-cr {
            color: ${variables.colorSecondary};
        }
    }
    .date {
        position: absolute;
        top: 10px;
        left: 15px;
    }
`;
