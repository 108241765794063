import produce from 'immer';
import { UPDATE_DEPARTMENT, RECEIVE_DEPARTMENTS, REMOVE_DEPARTMENT, ADD_MEMBER, REMOVE_MEMBER, UPDATE_MEMBER, UPLOAD_MEMBER_IMAGE } from '../actions/department';

export const departments = (state = {}, action) =>
    produce(state, (draft) => {
        let department, members, member, memberId, departmentId;
        switch (action.type) {
            case UPDATE_DEPARTMENT:
                draft[action.payload.id] = action.payload;
                break;
            case UPDATE_MEMBER:
                window.console.log(action.payload);
                memberId = action.payload.id;
                departmentId = action.payload.departmentId;
                if (draft[departmentId].members[memberId] === undefined) {
                    draft[departmentId].members[memberId] = action.payload;
                } else {
                    Object.assign(draft[departmentId].members[memberId], action.payload);
                }
                break;
            case UPLOAD_MEMBER_IMAGE:
                //each key must be updated
                member = Object.assign({}, draft[action.payload.departmentId].members[action.payload.memberId], { imageUrl: action.payload.imageUrl });
                members = Object.assign({}, draft[action.payload.departmentId].members, { [action.payload.memberId]: member });
                department = Object.assign({}, draft[action.payload.departmentId], { members: members });
                Object.assign(draft, { [action.payload.departmentId]: department });
                break;
            case ADD_MEMBER:
                //each key must be updated
                members = Object.assign({}, draft[action.payload.departmentId].members, action.payload.newMember);
                department = Object.assign({}, draft[action.payload.departmentId], { members: members });
                Object.assign(draft, { [action.payload.departmentId]: department });
                break;
            case REMOVE_DEPARTMENT:
                delete draft[action.payload];
                break;
            case REMOVE_MEMBER:
                memberId = action.payload.id;
                departmentId = action.payload.departmentId;
                //get department memmbers without removed member
                members = Object.keys(draft[departmentId].members)
                    .filter((key) => parseInt(key) !== parseInt(memberId))
                    .reduce((result, current) => {
                        result[current] = draft[departmentId].members[current];
                        return result;
                    }, {});
                Object.assign(draft, { [departmentId]: Object.assign(draft[departmentId], { members: members }) });
                break;
            case RECEIVE_DEPARTMENTS:
                Object.assign(draft, action['payload']);
                break;

            //no default
        }
    });
