import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TabLogin from '_dash/styleComponents/login/TabLogin';
import FormRegister from '_dash/styleComponents/login/FormRegister';
import { LoginContext } from '_dash/Contexts';
import variables from 'scss/base/_variables.module.scss';
//import { HeaderSt } from '_dash/styleComponents/HeaderStyle';

function RenderTabPanelLogin() {
    if (window.devMode) window.console.log(8, 'f.renderTabPanelLogin');
    const { loginContext } = React.useContext(LoginContext);

    switch (loginContext.activeTab) {
        case 'tab-login':
            return <TabLogin />;

        case 'tab-jobSeekerSignUp':
            return (
                <div className="tab-pane signUpBox active">
                    <h2>Candidate Sign Up</h2>
                    <FormRegister isCompany={false} />
                </div>
            );

        case 'tab-employerSignUp':
            return (
                <div className="tab-pane signUpBox active">
                    <FormRegister isCompany={true} />
                </div>
            );
        default:
            return <div className="tab-pane active">No tab found!</div>;
    }
}

RenderTabPanelLogin.propTypes = {
    activeTab: PropTypes.string,
    close: PropTypes.func,
};

export const LoginWrap = styled.div`
    transform: translateY(-120%);
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1202;
    overflow-y: auto;
    padding: 90px 15px 15px;
    transition: all 0.3s ease;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
        padding: 90px 60px 15px;
    }
    @media (max-width: 575px) {
        padding: 80px 25px 15px;
    }
    .close {
        position: absolute;
        top: 14%;
        font-size: 24px;
        right: 13%;
        @media (max-width: 1400px) {
            right: 9%;
        }
        @media (max-width: 1200px) {
            right: 55px;
        }
        @media (max-width: 991px) {
            right: 80px;
        }
        @media (max-width: 768px) {
            right: 60px;
            top: 85px;
        }
        @media (max-width: 575px) {
            right: 25px;
        }
        i:hover {
            color: red;
        }
    }
    .inner {
        padding: 15px 0;
        max-width: 550px;
        @media (max-width: 768px) {
            max-width: 100%;
            .btn {
                margin-top: 5px;
            }
        }
        h1 {
            font-size: 28px;
            @media (max-width: 575px) {
                margin-bottom: 10px;
            }
        }
        h2 {
            font-size: 20px;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
            @media (max-width: 992px) {
                margin-bottom: 0;
            }
            @media (max-width: 575px) {
                margin-top: 10px;
            }
        }
        label {
            &.alert {
                padding: 0;
            }
        }
    }
    .nav-tabs {
        border: none;
        justify-content: center;
        margin-top: 30px;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
        .nav-link {
            border: none;
            color: ${variables.colorPrimary};
            font-size: 17px;
            &:hover {
                border: none;
                color: ${variables.colorSecondary};
            }
            &.active {
                display: none;
            }
        }
    }
    .bottomOption {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .form-check {
            padding-left: 0;
        }
        .form-group {
            margin: 0;
        }
        button {
            margin-left: auto;
            color: ${variables.colorPrimary};
            font-weight: 600;
        }
    }
`;
export default function Login() {
    const { loginContext, dispatch } = React.useContext(LoginContext);

    return (
        <LoginWrap className="lw">
            <span className="close">
                <i
                    className="fas fa-times"
                    onClick={() => {
                        dispatch({ type: 'HIDE_LOGIN' });
                        //dispatch({ type: 'CHANGE_TAB', payload: { tab: 'tab-login' } });
                    }}
                />
            </span>
            <div className="inner">
                <h1 className="text-center">Welcome to Rezoomo</h1>
                <div className="tab-content navList-content">
                    <RenderTabPanelLogin />
                </div>
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" onClick={() => dispatch({ type: 'CHANGE_TAB', payload: { tab: 'tab-login' } })}>
                        <span className={'nav-link ' + (loginContext.activeTab === 'tab-login' ? ' active' : '')}>Already have an account?</span>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            dispatch({ type: 'CHANGE_TAB', payload: { tab: 'tab-jobSeekerSignUp' } });
                        }}
                    >
                        <span className={'nav-link ' + (loginContext.activeTab === 'tab-jobSeekerSignUp' ? ' active' : '')}>Register as Candidate</span>
                    </li>

                    <li
                        className="nav-item"
                        onClick={() => {
                            dispatch({ type: 'CHANGE_TAB', payload: { tab: 'tab-employerSignUp' } });
                        }}
                    >
                        <span className={'nav-link ' + (loginContext.activeTab === 'tab-employerSignUp' ? ' active' : '')}>Register as Employer</span>
                    </li>
                </ul>
            </div>
        </LoginWrap>
    );
}
