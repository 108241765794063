import { createContext } from 'react';

export const EmailContext = createContext({});
export const FrontJobApplyContext = createContext();
export const FrontTalentPoolApplyContext = createContext();
export const CVWizardContext = createContext();
export const CVBuilderContext = createContext();
export const FrontContext = createContext({});
export const LoginContext = createContext({});
export const JobEditContext = createContext();
export const ReportsContext = createContext();
export const VonqContext = createContext();
export const CalendarContext = createContext();
export const CandFlowProgressModalContext = createContext();
export const DocumentsContext = createContext({});
