// import 'babel-polyfill';// use when need to run on edge in development mode
// IE 11 fixes
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import pathToRegexp from 'path-to-regexp';

import App from '_dash/app';
import SweetAlert from 'modules/react-sweet-alert/index';
import 'modules/react-toast'; //window.toast will be imported
// import Loader from '_dash/components/Loader';

import 'bulma-tooltip';
import 'scss/loader.scss';
import 'css/fontawesome-pro/css/all.min.css';

import { getBrowserName } from '_dash/components/util';
import IENotSupportedBrowser from '_dash/components/IENotSupportedBrowser';
import { deleteCookie, getCookie, setCookie } from 'modules/cookies';

if (process.env.NODE_ENV === 'development') {
    window.devMode = true;
} else {
    window.devMode = false;
    if (window.innerWidth <= 1024) {
        window.oncontextmenu = function (event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };
    }
}

// add slash at final
function AddSlash() {
    // set a javascript cookie version if not exist, and clear some cookies
    // the reason for this is to update some cookies to use secure flag = true
    if (!getCookie('jscv')) {
        deleteCookie('cookiePolicy');
        deleteCookie('ul');
        deleteCookie('up');
        setCookie('jscv', 1, 9999);
    }
    // remove duplicate /(slash(s)) from path
    let path = window.location.pathname;
    while (path.search('//') > -1) {
        path = path.replace(/\/\//g, '/');
    }
    // update location.pathname if is the case
    if (path !== window.location.pathname) window.location.pathname = path;
    // add slash if is the case
    if (window.location.pathname.substr(window.location.pathname.length - 1) !== '/') {
        window.location.pathname += '/';
        return null;
    } else {
        if (getBrowserName() === 'msie') {
            return <IENotSupportedBrowser />;
        } else
            return (
                <React.Fragment>
                    <SweetAlert />
                    <App />
                </React.Fragment>
            );
    }
}

const container = document.getElementById('wrap');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <AddSlash />
    </React.StrictMode>
);

/* function Root() {
    if (window.devMode) window.console.log(1, 'root');
    // the order of routes is important, is the order how is parsed each one,  don't change it random
    const routes = ['/:page(company)/:companyUrl?/:companyPage?', '/:page(job)/:jobId?', '/:page(login)/:companyUrl?', '/:page?'];
    let urlParams = getParamsRoutes(routes, window.location.pathname);
    //get companyUrl from subdomain if exist
    const hostNameDot = window.location.hostname.split('.'); //ex www.rezoomo.com, myCompanyUrl.rezoomo.com
    //check if is company subdomain url,and exclude www(for live) and rezoomo-git(for dev)

    const excludePrefixes = ['www', 'rezoomo-git', 'rezoomolivedata', 'rezoomolive', 'livedemo'];
    if (hostNameDot.length > 1 && excludePrefixes.indexOf(hostNameDot[0]) === -1) {
        //don't allow number as subdomain in case we use the ip as domain
        if (isNaN(hostNameDot[0])) urlParams.companySubdomain = hostNameDot[0];
    }
    return <App {...urlParams} />;
}

//match a route with a path
function getParamsRoute(route, path) {
    const keys = [];
    const regexp = pathToRegexp(route, keys, { end: false });
    const result = regexp.exec(path);
    if (result === null) return null;
    let params = {};
    keys.forEach((key, index) => (params[key.name] = result[index + 1]));
    return params;
}
// will return the params of first route is matching the path
function getParamsRoutes(routes, path) {
    for (let index = 0; index < routes.length; index++) {
        const params = getParamsRoute(routes[index], path);
        // exist loop when found a valid route
        if (params !== null) {
            return params;
        }
    }
} */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
