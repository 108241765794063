import * as ActionConstants from '_dash/redux/constants';

export const companyGalleryReducer = (state = {}, action) => {
    const galleryKey = typeof action.payload !== 'undefined' && typeof action.payload.group === 'string' ? action.payload.group.toLowerCase() : false;
    let updatedArray = [];
    switch (action.type) {
        case ActionConstants.RECEIVE_COMPANY_GALLERY:
            return action.payload;
        case ActionConstants.GALLERY_ADD_ITEM:
            updatedArray = [...state[galleryKey], action.payload];
            return Object.assign({}, state, {
                [galleryKey]: updatedArray,
            });

        case ActionConstants.GALLERY_REMOVE_ITEM:
            updatedArray = state[galleryKey].filter((item) => item.id !== action.payload.id);
            return Object.assign({}, state, {
                [galleryKey]: updatedArray,
            });

        default:
            return state;
    }
};
