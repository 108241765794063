import * as ActionConstants from '_dash/redux/constants';

const fields = ['text', 'functions', 'locations'];

export const companyFutureRolesReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.FUTUREROLES_RECEIVE:
            return action.payload;

        case ActionConstants.FUTUREROLES_UPDATE: {
            let newState = Object.assign({}, state);
            fields.forEach((item) => {
                if (action.payload[item] !== undefined) {
                    // skip inexistent keys
                    switch (
                        item // deal with keys differently
                    ) {
                        case 'text': {
                            newState[item] = action.payload[item].toString(); // get a copy
                            break;
                        }

                        default:
                            // for the other two: industries & locations
                            newState = Object.assign({}, newState, {
                                [item]: action.payload[item],
                            });
                    }
                    // newState[item] = action.payload[item];
                }
            });
            return newState;
        }

        default: {
            return state;
        }
    }
};
