import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';

export const HeaderSt = styled.header`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
    transition: all 0.3s ease-in-out;
    &.sticky {
        box-shadow: 0px 10px 20px 0px rgba(79, 35, 35, 0.08);
        background-color: #fff;
    }
`;

export const Logo = styled.a`
    display: block;
    max-width: 240px;
    transition: all ease 0.3s;
    @media (max-width: 767px) {
        max-width: 195px;
    }
    > .main-logo {
        width: 100%;
    }
    .sticky-logoImg {
        display: none;
    }
    ${HeaderSt}.sticky & {
        max-width: 180px;
    }
`;

export const SiteNav = styled.nav`
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    padding: 0;
    width: 100%;
    /* margin-top: 10px; */
    transition: all 0.3s ease-in-out;
    ${HeaderSt}.sticky & {
        margin: 10px 0;
    }
    @media (max-width: 991px) {
        margin: 15px 0;
        position: relative;
    }
    .toggle-menu {
        display: none;
        @media (max-width: 991px) {
            position: absolute;
            left: 50px;
            width: 26px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: 25px;
            cursor: pointer;
            .bar {
                width: 18px;
                height: 2px;
                display: block;
                float: left;
                margin: 3px auto;
                transition: all 0.3s ease-in-out;
                background: #fff;
                ${HeaderSt}.sticky &,  ${HeaderSt}.nav-dark & {
                    background: ${variables.colorPrimary};
                }
                &:nth-child(2) {
                    width: 24px;
                }
            }
        }
        @media (max-width: 575px) {
            left: 5px;
        }
    }
    .site-logo {
        ${HeaderSt}.sticky &,  ${HeaderSt}.nav-dark & {
            .logoImg {
                display: none;
            }
            .sticky-logoImg {
                display: block;
            }
        }

        @media (max-width: 991px) {
            margin: 0 auto;
        }
    }
    .nav-login {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        &.nav-login-desktop {
            @media (max-width: 991px) {
                display: block;
                text-align: center;
                li {
                    display: none;
                    &.bt-em {
                        display: inline-block;
                        border-bottom: none;
                        a,
                        button {
                            margin: 10px 0;
                            padding: 7px;
                            line-height: 21px;
                            width: 130px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .u-img {
            border-radius: 100%;
            overflow: hidden;
            margin: 0;
            width: 48px;
            height: 48px;
            cursor: pointer;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3); //rgba(45, 157, 183, 0.6);
            @media (max-width: 991px) {
                width: 40px;
                height: 40px;
            }
            &.noImg {
                b {
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 10px;
                    @media (max-width: 991px) {
                        padding-top: 9px;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        }
        .log-menu {
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            min-width: 125px;
            padding: 10px 0;
            margin-top: 10px;
            @media (max-width: 991px) {
                margin-top: 5px;
            }
            a {
                color: ${variables.colorBase};
                padding: 5px 0;
                display: block;
                &:after {
                    display: none;
                }
                &:hover {
                    color: ${variables.colorSecondary};
                }
                ${HeaderSt}.sticky & {
                    padding: 5px 0;
                }
            }
        }
    }
    .nav-login-mobile {
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
        @media (min-width: 992px) {
            display: none;
        }
        @media (max-width: 575px) {
            right: 0;
            .userLog-menu ul.log-menu {
                left: auto;
                transform: translateY(0);
                right: 10%;
                a:after {
                    display: none;
                }
            }
        }
        li {
            margin: 0;
            .userLog-menu {
                margin: 0;
            }
            a {
                font-weight: 500;
            }
        }
        span {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            cursor: pointer;
            padding: 0;
            display: inline-block;
            ${HeaderSt}.nav-dark & {
                color: ${variables.colorBase};
            }
            ${HeaderSt}.sticky & {
                color: ${variables.colorBase};
            }
        }
        .log-menu {
            li {
                @media (max-width: 991px) {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    position: relative;
    @media (max-width: 991px) {
        display: none;

        position: fixed;
        height: 100vh;
        width: 320px;
        background: #fff;
        top: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
        overflow: scroll;
        z-index: 1;
    }
    li {
        margin: 0 17px;
        position: relative;
        display: inline-block;
        @media (max-width: 1200px) {
            margin: 0 10px;
        }
        @media (max-width: 991px) {
            padding: 0;
            margin: 0;
            border-bottom: 1px solid ${variables.colorBorder};
            display: block;
        }
        &.bt-em {
            margin: 0 15px;
            @media (max-width: 1200px) {
                margin: 0 10px;
            }
            a {
                padding: 7px 37px;
                line-height: 30px;
                font-weight: 600;
                color: #fff;
                box-shadow: 0 20px 30px 0 rgba(79, 35, 35, 0.15);
                &:hover {
                    color: ${variables.orange};
                }
                &:after {
                    display: none;
                }
                &.btn-tr {
                    color: ${variables.colorPrimary};
                    &:hover {
                        color: #fff;
                        border-color: ${variables.colorPrimary};
                    }
                    ${HeaderSt}.nav-dark & {
                        color: ${variables.colorPrimary};
                        &:hover {
                            color: #fff;
                        }
                    }
                    ${HeaderSt}.sticky & {
                        color: ${variables.colorPrimary};
                        &:hover {
                            color: #fff;
                        }
                    }
                }
                ${HeaderSt}.sticky & {
                    color: #fff;
                    padding: 7px;
                    @media (min-width: 992px) {
                        padding: 7px 37px;
                        line-height: 21px;
                    }
                    &:hover {
                        color: ${variables.orange};
                    }
                }
                ${HeaderSt}.nav-dark & {
                    color: #fff;
                    &:hover {
                        color: ${variables.orange};
                    }
                }
            }
        }
        a,
        span {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            position: relative;
            cursor: pointer;
            padding: 30px 0;
            display: inline-block;
            ${HeaderSt}.sticky & {
                color: ${variables.colorBase};
                padding: 0;
                &:hover {
                    color: ${variables.colorPrimary};
                    &:after {
                        display: none;
                    }
                }
                @media (max-width: 991px) {
                    padding: 12px 35px;
                    color: ${variables.colorInner};
                }
            }
            ${HeaderSt}.nav-dark & {
                color: ${variables.colorBase};
            }
            &:hover {
                color: #fff;
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 20px;
                width: 0;
                height: 2px;
                background: #fff;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                left: 0;
                ${HeaderSt}.nav-dark & {
                    background: ${variables.colorBase};
                }
            }
            @media (max-width: 991px) {
                color: ${variables.colorInner};
                display: block;
                padding: 12px 35px;
                font-weight: 400;
                &:hover {
                    color: ${variables.colorInner};
                }
                &:after {
                    display: none;
                }
            }
        }
    }
    .close-menu {
        display: none;
        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            margin-left: auto;
            top: 0;
            width: 120px;
            left: 0;
            padding: 2px;
            padding: 30px 20px;
            color: ${variables.colorInner};
            font-size: 16px;
            cursor: pointer;
            i {
                font-size: 27px;
                color: #2b2350;
                margin-left: 15px;
            }
        }
    }
`;
export const SiteMainMenu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    @media (min-width: 992px) {
        display: block;
        li.has-child:hover .sub-menu {
            opacity: 1;
            visibility: visible;
        }
        .sub-menu {
            display: block;
            margin: 0;
            list-style: none;
            background: #fff;
            box-shadow: 0 10px 40px 4px rgba(79, 35, 35, 0.3);
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            min-width: 680px;
            transition: all 0.3s ease-in-out;
            z-index: 999999;
            border-radius: 4px;

            visibility: hidden;
            opacity: 0;
            /* opacity: 1;
            visibility: visible; */
            @media (max-width: 1400px) {
                min-width: 600px;
            }
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin: 0 auto;
                top: -11px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 11px 11px 11px;
                border-color: transparent transparent rgba(255, 255, 255, 0.9) transparent;
                z-index: 1001;
            }
            ul {
                padding: 15px 20px 5px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
            li {
                display: inline-block;
                position: relative;
                margin: 0 6px;
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 1px;
                    right: -25px;
                    background-color: ${variables.colorBorder};
                    @media (max-width: 1400px) {
                        right: -13px;
                    }
                }
                > a {
                    color: ${variables.colorPrimary};
                    display: block;
                    margin-bottom: 15px;
                    padding: 0;
                    font-weight: 500;
                    &:hover {
                        color: ${variables.colorSecondary};
                    }
                    &:after {
                        display: none;
                    }
                    ${HeaderSt}.nav-dark & {
                        color: ${variables.colorPrimary};
                    }
                }
            }

            .list-txt {
                margin-left: -7px;
                a {
                    display: block;
                    padding: 7px 0 7px 32px !important;
                    font-weight: 400;
                    color: ${variables.colorInner};
                    cursor: pointer;
                    font-size: 13px;
                    position: relative;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        color: ${variables.colorPrimary};
                    }
                }
                img {
                    width: 32px;
                    height: 32px;
                    display: inline-block;
                    position: absolute;
                    left: -1px;
                    top: 0;
                }
            }
        }
        .bottom-subMenu {
            border-top: 1px solid ${variables.colorBorder};
            display: flex;
            flex-flow: row nowrap;
            a {
                display: block;
                flex: 0 0 50%;
                max-width: 50%;
                padding: 15px 15px 15px 20px;
                font-size: 15px;
                color: ${variables.colorPrimary};
                ${HeaderSt}.nav-dark & {
                    color: ${variables.colorPrimary};
                }
                /* &:first-child {
                    border-right: 1px solid ${variables.colorBorder};
                } */
                &:after {
                    display: none;
                }
                &:hover {
                    color: ${variables.colorSecondary};
                    i {
                        margin-left: 10px;
                    }
                }
                ${HeaderSt}.sticky & {
                    padding: 8px 15px 8px 20px;
                }
                i {
                    transition: all ease 0.3s;
                    margin-left: 10px;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .bottom-subMenu {
            display: none;
        }
        li {
            flex: 0 0 100%;
            max-width: 100%;
        }
        li.has-child {
            &.open {
                .sub-menu {
                    display: block;
                    .list-txt {
                        padding-left: 35px;
                        margin-bottom: 5px;
                        a {
                            padding: 5px 0 5px 32px;
                            position: relative;
                            img {
                                width: 32px;
                                height: 32px;
                                display: inline-block;
                                position: absolute;
                                left: -1px;
                                top: 2px;
                            }
                        }
                    }
                }
                > span {
                    border-bottom: 1px solid ${variables.colorBorder};
                    &:before {
                        content: '\f077';
                    }
                }
            }
            > span {
                &:before {
                    content: '\f078';
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: 'Font Awesome 5 Pro';
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .sub-menu {
            display: none;
        }
    }
`;
