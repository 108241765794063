import produce from 'immer';
import * as ActionConstants from '../constants';
/* const exampleList =  [
	{ id: 1, name: "Sales/Business Development Executive", loc: "Cork", date: "Jun 11,2018", nrApplicants: 5, actions: 5,isArchive:0,isDraft:0 },
	{ id: 2, name: "Marketing and PR Coordinator", loc: "Cork", date: "Jun 11,2018", nrApplicants: 2, actions: 5,isArchive:0,isDraft:0 }
]; */
export const companyJobsReducer = (state = [], action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionConstants.RECEIVE_COMPANY_JOBS:
                draft.splice(0, draft.length);
                let i;
                for (i in action.payload) {
                    draft.push(Object.assign({}, action.payload[i]));
                }
                break;
            /*case 'COMPANY_JOB_UPDATED':
                Object.assign(draft[draft.findIndex((job) => job.id === action.payload.id)], action.payload);
                break;
            case 'COMPANY_JOB_ADDED':
                draft.unshift(action.payload);
                break;
            case 'COMPANY_JOB_DELETED':
                draft.splice(
                    draft.findIndex((job) => job.id === action.payload),
                    1
                );
                break;
            case 'ADD_COMPANY_JOB':
                draft.push(action.payload);*/
            //no default
        }
    });
