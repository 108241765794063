import produce from 'immer';
import * as ActionConstants from '_dash/redux/constants';

const cvReducer = (state = {}, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionConstants.RECEIVE_CVDATA:
                Object.assign(draft, action.payload);
                break;

            case ActionConstants.UPDATE_CVDATA:
                Object.assign(draft, action.payload);
                break;

            case 'CV_END_WIZARD':
                Object.assign(draft, action.payload);
                break;

            default:
                break;
        }
    });
export default cvReducer;
