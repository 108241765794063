import { useContext } from 'react';
import { Fields, Field } from 'modules/react-fields-context';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';
import { MultipleUploadSt } from 'modules/react-fields-context/customTypes/MultipleUploadStyle';
import { apiFetch } from '../util';
import DocumentsFile from './DocumentsFile';
import { DocumentsContext } from '_dash/Contexts';
import DocumentsEditFolder from './DocumentsEditFolder';
import DocumentsLimit from './DocumentsLimit';
import DocumentsProvider from './DocumentsProvider';
import { FetchNextApi } from 'modules/next-fetch';

export function Documents() {
    return (
        <DocumentsProvider>
            <div>
                <div className="container-fluid">
                    <div className="text-right">
                        <NavLink to="/dash/company/" className="btn btn-trs mt-0">
                            <i className="fas fa-angle-left" />
                            Back to Set up guide
                        </NavLink>
                    </div>
                    <DocumentsLimit />
                    <DocsGallery />
                </div>
            </div>
        </DocumentsProvider>
    );
}

function RenderTabPanel({ elem, isEditMode }) {
    const { fetchDocs } = useContext(DocumentsContext);
    const handleAddFiles = async (data, doneSubmit) => {

        const formData = new FormData();

        formData.append('folderId', elem.id);

        data.attachment.forEach((file, i) => {
            formData.append('attachment['+i+']', file);
        });

        const fetchNextApi = new FetchNextApi('company/document', {
            method: 'post',
            body: formData
        });
        
        fetchNextApi
            .fetch()
            .then(async response => {
                if (response.success) {

                    doneSubmit({ attachment: undefined });

                    await fetchDocs();

                    response.statusFiles.forEach(statusFile => {
                        if(statusFile.status === 'success') window.toast({ type: 'info', text: statusFile.msg });
                        else window.toast({ type: 'warning', text: statusFile.msg });
                    });
                    
                } else {
                    window.toast({ type: 'error', text: response.msg });
                }
            });
    };
    return (
        <div className="tab-pane active">
            <DocumentPanel>
                {isEditMode && (
                    <div className="col-addDoc bg-grey">
                        <p className="topHead">Add Files</p>
                        <AddFiles handleAddFiles={handleAddFiles} galleryShow={false} />
                    </div>
                )}
                <div className="col-items row no-gutters">
                    {elem.docs.map((e, index) => {
                        return <DocumentsFile key={index} e={e} isEditMode={isEditMode} />;
                    })}
                </div>
            </DocumentPanel>
        </div>
    );
}

export function AddFiles({ handleAddFiles, galleryShow }) {
    return (
        <Fields onSubmit={handleAddFiles} showSubmit={false} tag="div" className="uploadNewDoc">
            <Field
                name="attachment"
                className="mb-0"
                showSubmit={true}
                galleryShow={galleryShow}
                schema={{
                    type: 'multipleUpload',
                    rules: {
                        fileExtensionAccept: ['pdf', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'odp', 'xls', 'xlsx', 'xlsm', 'xlsb', 'ods'],
                        fileMaxMbSize: 10,
                        maxFilesCount: 5,
                        required: true,
                    },
                }}
            />
        </Fields>
    );
}

export function DocsGallery({ isEditMode = true }) {
    const { docs, activeFolderId, setActiveFolderId, setAddedFolderId } = useContext(DocumentsContext);
    const { fetchDocs } = useContext(DocumentsContext);
    const handleAddFolder = async (parentId) => {
        const newFolderId = await apiFetch({ action: 'api.dash.employer.company.document.addFolder', parentId });
        setAddedFolderId(newFolderId);
        await fetchDocs();
        setActiveFolderId(newFolderId);
    };
    const parentFolders = docs.filter((d) => !d.parentId);
    const activeFolder = docs.filter((f) => f.id === activeFolderId)[0];

    return (
        <FlexWrap>
            <div className="left-side">
                <div className="card">
                    <div className="card-body">
                        <div className="topHead d-flex align-items-center">
                            Documents
                            {isEditMode === true && (
                                <span className="btn btn-trs my-0 ml-auto" onClick={handleAddFolder}>
                                    <i className="far fa-plus" />
                                    Add Folder
                                </span>
                            )}
                        </div>
                        <ul className="nav nav-tabs">
                            {parentFolders.map((elem, index) => (
                                <li key={index} className="nav-item">
                                    <div className={'nav-link'}>
                                        <DocumentsEditFolder elem={elem} isEditMode={isEditMode} handleAddFolder={handleAddFolder} />
                                        {docs
                                            .filter((d) => elem.id === d.parentId)
                                            .map((child) => (
                                                <DocumentsEditFolder elem={child} isEditMode={isEditMode} />
                                            ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="right-side">
                <div className="card">
                    <div className="card-body p-0">
                        <div className="tab-content">{activeFolder && <RenderTabPanel elem={activeFolder} isEditMode={isEditMode} />}</div>
                    </div>
                </div>
            </div>
        </FlexWrap>
    );
}

const FlexWrap = styled.div`
    display: flex;
    @media (max-width: 992px) {
        flex-flow: row wrap;
    }
    .left-side {
        flex: 0 0 350px;
        width: 350px;
        padding-right: 15px;
        @media (max-width: 1200px) {
            flex: 0 0 270px;
            width: 270px;
        }
        @media (max-width: 992px) {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 15px;
            padding-right: 0;
        }
        .card .card-body {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .right-side {
        flex: 1;
        padding-left: 15px;
        @media (max-width: 992px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
        }
    }
    .card {
        box-shadow: none;
        border: 1px solid ${variables.colorBorder};
        height: 100%;
    }
    .topHead {
        font-weight: 600;
        color: ${variables.colorBase};
        margin: 5px 0 15px;
        @media (max-width: 575px) {
            margin: 0;
        }
        .btn {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .nav-tabs {
        .nav-item {
            width: 100%;
            padding-left: 0;
            margin-bottom: 0;
            border-bottom: 1px solid aliceblue;
        }
        .nav-link {
            //cursor: pointer;
            border: none;
            padding-left: 10px;
            padding-right: 5px;
            /* &:before {
                content: '\f105';
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: 'Font Awesome 5 Pro';

                position: absolute;
                left: 2px;
                top: 5px;
                font-size: 16px;
                opacity: 0;
                transform: translateX(5px);
                transition: all ease 0.3s;
            } */
            &.active {
                border: none;
                /* &:before {
                    opacity: 1;
                    transform: translateX(0);
                } */
            }
            &:hover {
                border: none;
            }
            /* button.cta-delete {
                position: absolute;
                right: -3px;
                top: 8px;
                &:hover {
                    svg {
                        fill: ${variables.colorRed};
                    }
                }
            } */
        }
    }
    .tab-content,
    .tab-pane {
        height: 100%;
    }
`;

const DocumentPanel = styled.div`
    display: flex;
    height: 100%;
    @media (max-width: 575px) {
        flex-flow: row wrap;
    }
    .col-addDoc {
        padding: 20px 15px;
        flex: 0 0 145px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        align-items: center;
        @media (max-width: 575px) {
            padding: 10px;
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            .uploadNewDoc {
                margin-left: auto;
                max-width: 220px;
            }
        }
    }
    ${MultipleUploadSt} {
        @media (max-width: 575px) {
            margin: 0;
            border: 1px solid ${variables.colorPrimary};
            padding: 3px 5px 5px 5px;
            border-radius: 20px;
        }
        .dropdown-menu.tooltip-dropdown-menu.pos-top {
            @media (max-width: 992px) {
                left: 110%;
                .arrow {
                    left: 33%;
                }
            }
            @media (max-width: 575px) {
                left: 0;
                .arrow {
                    left: 65%;
                }
            }
        }
        .inner {
            border: none;
        }
        ul {
            li {
                padding-left: 0 !important;
            }
        }
    }
    .col-items {
        padding: 20px 15px;
        flex: 1 1;
        @media (max-width: 575px) {
            padding: 15px 5px;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .file-box {
        padding-left: 7.5px !important;
        padding-right: 7.5px !important;
    }
    .inner {
        border: 1px solid #ebf1f5;
        margin-bottom: 15px;
        border-radius: 3px;
    }
    .doc-body {
        height: 130px;
        text-align: center;
        border-radius: 3px 3px 0 0;
        position: relative;
        @media (max-width: 575px) {
            height: 110px;
        }
        > i {
            font-size: 42px;
            line-height: 105px;
            @media (max-width: 575px) {
                font-size: 30px;
                line-height: 80px;
            }
        }
    }
    .selectItem {
        position: absolute;
        top: 2px;
        border: 2px solid ${variables.colorBorder};
        width: 22px;
        height: 22px;
        right: 2px;
        border-radius: 3px;
        text-align: center;
        &:before {
            content: '\f00c';
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: 'Font Awesome 5 Pro';

            color: ${variables.colorSecondary};
            font-size: 15px;
            display: none;
        }
        &.checked {
            &:before {
                display: block;
            }
        }
    }
    .cta {
        position: absolute;
        bottom: 5px;
        right: 0;
        left: 0;
        width: 100%;
        span {
            cursor: pointer;
            padding: 5px 8px;
            font-size: 16px;
            transition: all ease 0.3s;
            &.delete {
                &:hover {
                    color: ${variables.colorRed};
                    &:hover i {
                        &:before {
                            content: '\f1f8';
                        }
                    }
                }
            }
            &.download {
                &:hover {
                    color: ${variables.colorPrimary};
                }
            }
            &.view {
                &:hover {
                    color: ${variables.colorSecondary};
                }
            }
        }
    }
    .doc-footer {
        border-top: 1px solid #ebf1f5;
        height: 65px;
        padding: 5px 5px 5px 7px;
        border-radius: 0 0 2px 2px;
    }
    .name {
        color: ${variables.colorBase};
        margin-bottom: 2px;
        position: relative;
        padding-right: 45px;
        p {
            font-size: 13px;
            padding-right: 3px;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                padding-right: 5px;
            }
        }
    }
    .date,
    .addedBy {
        font-size: 13px;
    }

    .size {
        font-size: 12px;
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 500;
    }
`;
