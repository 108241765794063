import React from 'react';
import { Fields, Field, Submit } from 'modules/react-fields-context';
import { apiFetch } from '_dash/components/util';
import ClassNames from 'classnames';
import Spinner from 'modules/react-spinner';
import { Button, FormSignUpWrap } from '_dash/styleComponents/CommonStyle';

export default function RequestResetPassword({ notActive, goBack }) {
    const handleReset = (dataForm, doneSubmit) => {
        dataForm['action'] = 'api.front.Login.resetPasswordRequest';
        apiFetch(dataForm).then((r) => {
            if (r.text === 'inactive') {
                notActive(dataForm.userLogin);
            } else {
                window.swal(r);
            }
            doneSubmit();
        });
    };
    return (
        <div className="tab-pane active">
            <h2 className="mb-4">Reset Password</h2>
            <Fields onSubmit={handleReset}>
                <FormSignUpWrap>
                    <div className="form-row">
                        <Field
                            name="userLogin"
                            placeholder="Enter Email"
                            // defaultValue={new URLSearchParams(window.location.search).get('userEmail')}
                            className="col-sm-12 emailBox"
                            schema={{
                                type: 'text',
                                rules: { email: '', required: '' },
                            }}
                        />
                    </div>
                </FormSignUpWrap>

                <Submit
                    render={(isLoading, isSaved, submit) => {
                        return (
                            <p className="d-flex mb-0">
                                <Button onClick={submit} disabled={isLoading} className={ClassNames('btn', { 'btn-isLoading': isLoading })}>
                                    {isLoading && <Spinner />}
                                    Reset Password
                                </Button>
                                <button type="button" onClick={goBack} className="ml-auto blue-txt">
                                    Go To Login
                                </button>
                            </p>
                        );
                    }}
                />
                {/* <div className="bottomOption">
                    <button type="button" onClick={goBack} style={{ color: '#2d9db7' }}>
                        Go To Login
                    </button>
                </div> */}
            </Fields>
        </div>
    );
}
