import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
//import { render } from 'react-dom';
import { EditorState, ContentState, convertFromHTML, convertToRaw, Entity, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import { stateToHTML } from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import { IconPencilEdit } from '_dash/components/Icon';
import { withRouter } from "react-router";

const defaultEditorToolbar = {
    options: ['inline', 'list', 'link', 'history'],
    //fontSize: { options: ['14px', '16px', '18px'] },
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    history: {
        inDropdown: false,
        options: ['undo'],
    },
};

const getHtml = (content) => {
    //editor convert &zwnj; to \u200c, server convert \u200c to ?, so need to remove
    const html = draftToHtml(convertToRaw(content)).replace(/\u200c/g, '');
    return html;
};
class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),

            isEditable: props.editorOpen,
            changeReadOnly: props.changeReadOnly,
            isHovering: false,
        };

        this.handleOpenEdit = this.handleOpenEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.doSave = this.doSave.bind(this);
        this.insertPlaceholder = this.insertPlaceholder.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    onEditorStateChange(editorState) {
        if (this.props.onChange) {
            const presentHtml = getHtml(this.state.editorState.getCurrentContent());
            const nextHtml = getHtml(editorState.getCurrentContent());
            // check if should update
            if (presentHtml !== nextHtml) {
                //local module forms need the value for onChange event
                this.props.onChange(nextHtml);
            }
        }
        this.setState({ editorState });
    }

    getEditorStateFromProps(content) {
        let editorState;
        if (content.length > 0) {
            // try {
            // 	const DBEditorState = convertFromRaw(JSON.parse(content));
            // 	editorState = EditorState.createWithContent(DBEditorState);
            // } catch (e) {
            const blocksFromHTML = convertFromHTML(content.replace(/<p><\/p>/g, '<p>&zwnj;</p>')); //&zwnj; is a invisible char, is need it because the converter will remove empty tags
            editorState = EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap));
            // }
        } else {
            editorState = EditorState.createEmpty();
        }
        return editorState;
    }

    doSave(e) {
        e.preventDefault();
        const plainText = this.state.editorState.getCurrentContent().getPlainText();
        if (plainText === this.props.content) {
            return this.handleCancel(e);
        } else {
            return this.handleSubmit(e);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // update editorState when props.content change and the state not
        if (prevProps.content.length !== this.props.content.length && prevState.editorState === this.state.editorState) {
            this.setState({ editorState: this.getEditorStateFromProps(this.props.content) });
        }
    }

    // is not the same as when the constructor generate the state.editorState, the child component wasn't receiving this prop on 2nd mount
    componentDidMount() {
        const { content } = this.props;
        if (content) {
            this.setState({ editorState: this.getEditorStateFromProps(content) });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const plainText = this.state.editorState.getCurrentContent().getPlainText();
        let content = '';
        if (plainText.length > 0) {
            content = getHtml(this.state.editorState.getCurrentContent());
            //content = stateToHTML(this.state.editorState.getCurrentContent());
            // content = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
        }
        this.props.doUpdate(content);
        this.setState({ isEditable: false });
        if (typeof this.props.editableChange === 'function') {
            this.props.editableChange(false);
        }
        return false;
    }

    handleCancel(e) {
        e.preventDefault();

        let newState = {
            editorState: this.getEditorStateFromProps(this.props.content),
        };
        if (this.props.content.length || this.props.allowCancelOnEmpty) {
            newState.isEditable = false;
        }
        this.setState(newState);
        if (typeof this.props.editableChange === 'function') {
            this.props.editableChange(false);
        }

        if (typeof this.props.doCancel === 'function') this.props.doCancel();
        return false;
    }

    handleOpenEdit() {
        this.setState({ isEditable: true });
        if (typeof this.props.editableChange === 'function') {
            this.props.editableChange(true);
        }
    }
    insertPlaceholder(label, meta) {
        const currentContent = this.state.editorState.getCurrentContent();
        const selection = this.state.editorState.getSelection();
        const entityKey = Entity.create('PLACEHOLDER', 'IMMUTABLE', { meta });
        const textWithEntity = Modifier.insertText(currentContent, selection, label, null, entityKey);

        const newEditorState = EditorState.push(this.state.editorState, textWithEntity, 'insert-characters');

        if (this.props.onChange) {
            const nextHtml = getHtml(newEditorState.getCurrentContent());
            this.props.onChange(nextHtml);
        }

        this.setState({
            editorState: newEditorState,
        });
    }

    render() {
        const { editorState, isEditable } = this.state;
        const { allowCancelOnEmpty, ...props } = this.props;
        const { jobId } = this.props.match.params
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        // let hidePlaceholder = false;
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                // hidePlaceholder = true;
            }
        }
        // const className = ClassNames('RichEditor-editor', { 'RichEditor-hidePlaceholder': hidePlaceholder });

        let buttonHandle = '';
        if (isEditable) {
            if (!contentState.hasText() && !allowCancelOnEmpty) {
                buttonHandle = (
                    <div className="btn-actions">
                        <button type="submit" className="btn btnSave" onClick={this.doSave}>
                            Save
                        </button>
                        &nbsp;
                    </div>
                );
            } else {
                buttonHandle = (
                    <div className="btn-actions">
                        <button type="submit" className="btn btnSave" onClick={this.doSave}>
                            Save
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-trs" onClick={this.handleCancel}>
                            Cancel
                        </button>
                    </div>
                );
            }
        } else if (this.props.content.length > 0) {
            buttonHandle = (
                <p key={this.props.editorKey} className="actions-field btnEdit top-0" onClick={this.handleOpenEdit}>
                    <span className="circle">
                        <IconPencilEdit />
                    </span>
                </p>
            );
        }
        const { onChange, ...safeProps } = props;
        return (
            <div
                className={ClassNames(
                    'editorWrapper',
                    { 'variable-messages': props.hasVariable },
                    { viewEditor: isEditable },
                    { onlyEdit: !this.state.changeReadOnly },
                    { [this.props.className]: this.props.className }
                )}
            >
                {this.state.changeReadOnly ? buttonHandle : ''}
                <Editor
                    {...safeProps}
                    editorState={editorState}
                    toolbarClassName="toolbar"
                    wrapperClassName="editor-root"
                    editorClassName="text-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbarHidden={!isEditable}
                    readOnly={!isEditable}
                    ref="editor"
                    //stripPastedStyle={true}
                    handlePastedText={() => false}
                    toolbar={this.props.editorToolbar}
                />
                {props.hasVariable && (
                    <div className={'variable-field' + (this.state.isHovering === true ? ' show-var' : '')}>
                        {/* <div className="icon-add inline-txt" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}> */}
                        <div className="icon-add inline-txt" onMouseEnter={() => this.setState({ isHovering: true })} onMouseLeave={() => this.setState({ isHovering: false })}>
                            <span className="icon">
                                <i className="far fa-plus" />
                            </span>
                            <div className="var-add">
                                <div>
                                    <p className="title">Variables:</p>
                                    <p>
                                        <button
                                            type="button"
                                            className="auto_variables"
                                            onClick={this.insertPlaceholder.bind(null, '[Candidate First Name]')}
                                        // onClick={() => {
                                        //     insertAtCursor('[Candidate First Name]');
                                        // }}
                                        >
                                            <span>[</span> Candidate First Name <span>]</span>
                                        </button>
                                    </p>
                                    <p>
                                        <button
                                            type="button"
                                            className="auto_variables"
                                            onClick={this.insertPlaceholder.bind(null, '[Candidate Last Name]')}
                                        // onClick={() => {
                                        //     insertAtCursor('[Candidate First Name]');
                                        // }}
                                        >
                                            <span>[</span> Candidate Last Name <span>]</span>
                                        </button>
                                    </p>
                                    <p>
                                        <button
                                            type="button"
                                            className="auto_variables"
                                            onClick={this.insertPlaceholder.bind(null, '[Your Name]')}
                                        // onClick={() => {
                                        //     insertAtCursor('[Your Name]');
                                        // }}
                                        >
                                            <span>[</span> Your Name <span>]</span>
                                        </button>
                                    </p>
                                    <p>
                                        <button type="button" className="auto_variables" onClick={this.insertPlaceholder.bind(null, '[Company Name]')}>
                                            <span>[</span> Company Name <span>]</span>
                                        </button>
                                    </p>
                                    {(jobId || props.hasVariableJob) && (
                                        <>
                                            <p>
                                                <button type="button" className="auto_variables" onClick={this.insertPlaceholder.bind(null, '[Job Title]')}>
                                                    <span>[</span> Job Title <span>]</span>
                                                </button>
                                            </p>
                                            <p>
                                                <button type="button" className="auto_variables" onClick={this.insertPlaceholder.bind(null, '[Job Link]')}>
                                                    <span>[</span> Job Link <span>]</span>
                                                </button>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default withRouter(TextEditor);

TextEditor.propTypes = {
    changeReadOnly: PropTypes.bool,
    content: PropTypes.string,
    doUpdate: PropTypes.func,
    doCancel: PropTypes.func,
    editorOpen: PropTypes.bool,
    editorKey: PropTypes.string,
    allowCancelOnEmpty: PropTypes.bool,
    editableChange: PropTypes.func,
    className: PropTypes.string,
    hasVariable: PropTypes.bool,
    editorToolbar: PropTypes.object
};
TextEditor.defaultProps = {
    changeReadOnly: false,
    content: '',
    editorOpen: true,
    allowCancelOnEmpty: true,
    hasVariable: false,
    editorToolbar: defaultEditorToolbar
};
