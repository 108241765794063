import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Spinner from 'modules/react-spinner';

class SweetAlertPortal extends React.Component {
    render() {
        return ReactDOM.createPortal(this.props.children, document.getElementById('modal-root'));
    }
}
SweetAlertPortal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.element, PropTypes.bool]),
};

const initState = {
    show: false,
    confirmButtonText: 'OK',
    closeButtonText: 'No',
    isConfirming: false,
    onConfirm: undefined, // this will became a function when used
    onReject: undefined, // this will became a function when used
    showCancelButton: false,
    showConfirmButton: true,
    title: '',
    text: '',
    clsname: '',
};

export class SweetAlert extends React.Component {
    constructor() {
        super();
        this.state = Object.assign({}, initState);
        window.swal = this.swal.bind(this);
    }

    swal(data) {
        if (data.onConfirm !== undefined) {
            if (data.confirmButtonText === undefined) data.confirmButtonText = 'Yes';
            if (data.showCancelButton === undefined) data.showCancelButton = true;
        }
        data.show = true;
        this.setState(Object.assign({}, initState, data));
    }

    closeAlert = () => this.setState(initState);

    /* handleConfirm = () => {
		this.setState({ isConfirming: true }, () => {
			if (typeof this.state.onConfirm === 'function') {
				this.state.onConfirm(this.closeAlert);
			} else {
				this.closeAlert();
			}
		});
	};

	handleClose = () => {
		this.setState({ isConfirming: true }, () => {
			if (typeof this.state.onConfirm === 'function') {
				this.state.onReject(this.closeAlert);
			} else {
				this.closeAlert();
			}
		});
	}; */

    handleAction = (action) => {
        this.setState({ isConfirming: true }, () => {
            if (action === 'confirm' && typeof this.state.onConfirm === 'function') {
                this.state.onConfirm(this.closeAlert);
            } else if (action === 'close' && typeof this.state.onReject === 'function') {
                this.state.onReject(this.closeAlert);
            } else {
                this.closeAlert();
            }
        });
    };

    closeByEsc = (e) => {
        if (e.keyCode === 27) {
            this.closeAlert();
        }
    };
    componentDidMount() {
        document.addEventListener('keydown', this.closeByEsc, true);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.closeByEsc, true);
    }
    render() {
        const { type, text, title, confirmButtonText, showCancelButton, showConfirmButton, isConfirming, closeButtonText, clsname } = this.state;
        return (
            <SweetAlertPortal>
                {this.state.show && (
                    <div className="modal modal-sweetAlert fade show " style={{ display: 'block', background: 'rgb(0,0,0,0.7)', zIndex: '10000' }}>
                        <div className="modal-sm modal-dialog modal-dialog-centered">
                            <div className="modal-content text-center modal-deleteUser">
                                <div className={'modal-body ' + clsname}>
                                    <h1>
                                        <big className={'text-' + type + ' icon-' + type} />
                                    </h1>
                                    <p />
                                    {title && <h3>{title}</h3>}
                                    {text && <div>{text}</div>}
                                    {/*actions*/}
                                    {showConfirmButton && (
                                        <button className={'btn ' + (isConfirming ? 'btn-isLoading' : '')} onClick={() => this.handleAction('confirm')}>
                                            {isConfirming && <Spinner />}
                                            {confirmButtonText}
                                        </button>
                                    )}
                                    &nbsp;
                                    {showCancelButton && (
                                        <button className={'btn btn-trs ' + (isConfirming ? 'btn-isLoading' : '')} onClick={() => this.handleAction('close')}>
                                            {isConfirming && <Spinner />}
                                            {closeButtonText}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </SweetAlertPortal>
        );
    }
}
