import { useContext, useState, useEffect } from 'react';
import Modal from 'modules/react-modal';
import { DocsGallery } from '_dash/components/company/Documents';
import { useSelector } from 'react-redux';
import { DocumentsContext } from '_dash/Contexts';
import { FetchNextApi } from 'modules/next-fetch';

export default function MultipleUploadDocsGallery({ setOpenGalleryModal, handleUpload, setIsOpenDropdown, setIsLoading }) {
    const siteUrl = useSelector((state) => state.paths.siteUrl);
    const { docs } = useContext(DocumentsContext);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        let selected = [];
        docs.forEach((folder) => {
            selected = selected.concat(folder.docs.filter((d) => d.isSelect));
        });
        setSelectedFiles(selected);
        console.log(selectedFiles);
    }, [docs]);

    const addFiles = async () => {
        setOpenGalleryModal(false);

        // const selectedFiles = docs
        //     .reduce(function (total, crVal) {
        //         return total.docs.concat(crVal.docs);
        //     })
        //     .filter((e) => e.isSelect);
        if (selectedFiles.length > 0) {
            setIsLoading(true);
            const e = {
                target: {
                    files: [],
                },
            };
            for (let i = 0; i < selectedFiles.length; i++) {
                
                const fetchNextApi = new FetchNextApi('company/document?' + new URLSearchParams({
                    id: selectedFiles[i].id
                }), {
                    method: 'get'
                });
                
                const fileContent = await fetchNextApi
                    .fetch()
                    .then(function (response) {
                        return response.blob();
                    })
                    .catch(error => {
                        console.log(error);
                    });

                // create the file for upload
                const file = new File([fileContent], selectedFiles[i].name, {
                    lastModified: new Date(),
                    type: selectedFiles[i].contentType,
                });
                e.target.files.push(file);
            }
            handleUpload(e);
            setIsLoading(false);
        }

        setIsOpenDropdown(true);
    };

    return (
        <Modal className="precent-width-98 galleryMediaSelected">
            {(handleClose) => (
                <div className="modal-content">
                    <span
                        className="close"
                        onClick={() => {
                            setOpenGalleryModal(false);
                            setIsOpenDropdown(true);
                        }}
                    >
                        <i className="far fa-times" />
                    </span>
                    <div className="modal-header">
                        <h1>Select files from gallery</h1>
                    </div>
                    <div className="modal-body modal-body-form px-3">
                        <DocsGallery isEditMode={false} />
                        <div className="text-right">
                            <button onClick={addFiles} className="btn">
                                Add Files ( <span>{selectedFiles.length}</span> )
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
