import * as ActionConstants from '_dash/redux/constants';

const cultureFields = ['mission', 'vision', 'values', 'media1', 'media2', 'media3'];

export const companyCultureReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionConstants.RECEIVE_COMPANY_CULTURE: {
            return action.payload ?? null;
        }

        case ActionConstants.CULTURE_UPDATE: {
            const newState = Object.assign({}, state, doUpdateCulture(action.payload));
            return newState;
        }

        case ActionConstants.CULTURE_ADD_MEDIA: {
            let newGallery = Object.assign({}, state.gallery);
            newGallery[action.payload.placeholder] = action.payload;
            let newState = Object.assign({}, state, {
                gallery: newGallery,
            });
            return newState;
        }

        case ActionConstants.CULTURE_REMOVE_MEDIA: {
            let newGallery = Object.assign({}, state.gallery);
            delete newGallery[action.payload.placeholder];
            let newState = Object.assign({}, state, {
                gallery: newGallery,
            });
            return newState;
        }

        case ActionConstants.CULTURE_CAREER_ADD: {
            let newItems = state[action.payload.type + 'Items'].slice(); // copy
            newItems.push(action.payload);
            let newObj = {};
            newObj[action.payload.type + 'Items'] = newItems;
            return Object.assign({}, state, newObj);
            // return Object.assign({}, state, {careerItems: newItems});
        }

        case ActionConstants.CULTURE_CAREER_REMOVE: {
            let newItems = state[action.payload.type + 'Items'].filter((item) => item.id !== action.payload.id); // copy
            let newObj = {};
            newObj[action.payload.type + 'Items'] = newItems;
            return Object.assign({}, state, newObj);
        }

        case ActionConstants.CULTURE_CAREER_UPDATE: {
            const index = state[action.payload.type + 'Items'].findIndex((elem) => elem.id === action.payload.id); // find index of elem to update
            const newItem = Object.assign({}, state[action.payload.type + 'Items'][index], action.payload); // update the element

            let newItems = state[action.payload.type + 'Items'].slice(); // copy
            newItems[index] = newItem; // update the old element

            let newObj = {};
            newObj[action.payload.type + 'Items'] = newItems; // updating typeItems

            return Object.assign({}, state, newObj); // returning the new state
        }

        case ActionConstants.CULTURE_STATS_ADD: {
            let newStats = Object.assign({}, state.stats); // get a shallow copy
            switch (action.payload.type) {
                case 'culture_employeeratio': {
                    // same as update
                    newStats.employeeRatio = Object.assign({}, state.stats.employeeRatio, action.payload);

                    break;
                }

                case 'culture_headcounts': {
                    newStats.headcountsRatio = state.stats.headcountsRatio.slice();
                    newStats.headcountsRatio.push(Object.assign({}, action.payload));

                    break;
                }
                //no default
            }

            let newObj = {};
            newObj['stats'] = newStats;

            return Object.assign({}, state, newObj);
        }

        case ActionConstants.CULTURE_STATS_UPDATE: {
            let newStats = Object.assign({}, state.stats); // get a shallow copy
            switch (action.payload.type) {
                case 'culture_employeeratio':
                    newStats.employeeRatio = Object.assign({}, state.stats.employeeRatio, action.payload);
                    break;

                case 'culture_headcounts':
                    {
                        newStats.headcountsRatio = state.stats.headcountsRatio.slice();
                        // get the element, update it
                        const elem = newStats.headcountsRatio.find((elem) => elem.id === action.payload.id);
                        // get the index, overwrite the index with the updated element
                        const elemIndex = newStats.headcountsRatio.findIndex((elem) => elem.id === action.payload.id);
                        newStats.headcountsRatio[elemIndex] = Object.assign({}, elem, action.payload);
                    }
                    break;
                //no default
            }

            let newObj = {};
            newObj['stats'] = newStats;

            return Object.assign({}, state, newObj);
        }

        case ActionConstants.CULTURE_STATS_REMOVE: {
            let newStats = Object.assign({}, state.stats); // get a shallow copy
            switch (action.payload.type) {
                case 'culture_employeeratio':
                    // same as update
                    newStats.employeeRatio = Object.assign({}, state.stats.employeeRatio, action.payload);
                    break;
                case 'culture_headcounts':
                    newStats.headcountsRatio = state.stats.headcountsRatio.filter((item) => item.id !== action.payload.id);
                    break;
                //no default
            }

            let newObj = {};
            newObj['stats'] = newStats;

            return Object.assign({}, state, newObj);
        }

        // only process for headcount-ratio since only this one refreshes
        case ActionConstants.CULTURE_STATS_ADD_REFRESH: {
            let newStats = Object.assign({}, state.stats); // get a shallow copy
            newStats.headcountsRatio = action.payload.slice();
            let newObj = {};
            newObj['stats'] = newStats;
            return Object.assign({}, state, newObj);
        }

        // only process for headcount-ratio since only this one refreshes
        case ActionConstants.CULTURE_STATS_REMOVE_REFRESH: {
            let newStats = Object.assign({}, state.stats); // get a shallow copy
            newStats.headcountsRatio = action.payload.slice();
            let newObj = {};
            newObj['stats'] = newStats;
            return Object.assign({}, state, newObj);
        }

        default: {
            return state;
        }
    }
};

const doUpdateCulture = (payload) => {
    let result = {};
    cultureFields.forEach((item) => {
        // maybe could have used Array.every()
        switch (item) {
            default:
                if (typeof payload[item] !== 'undefined') {
                    result[item] = payload[item];
                }
                break;
        }
    });
    return result;
};
