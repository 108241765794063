import React, { useReducer, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SelectInput.scss';
import classnames from 'classnames';
// import {eventDelay} from '_dash/components/util';
import { apiFetch, innerText, cfSlug } from '_dash/components/util';
import debounce from 'modules/debounce';

import produce from 'immer';
//import differ from 'deep-diff';
//import { windowWhen } from 'rxjs/operators';

const debounced = debounce(() => new Promise((resolve) => resolve()), 500);
const dataInitState = {
    displayList: [], //used to display data in drop down list
    index: 0, //set to first index  in displayList
    inputValue: '', //populate when you start write in input
    isFetching: false,
    showList: false, //show/hide drop down list
};

const TYPE_CURRENT = 'current';
const TYPE_REMOVED = 'remove';
const TYPE_NEW = 'new';

const stateReducer = (state, action) => {
    return produce(state, (draft) => {
        // window.console.log('stateReducer.action', action); // @debug TODO some calls (apparently those from events) are doubled
        switch (action.type) {
            case 'init':
                // draft = Object.assign({}, action.payload);
                Object.entries(action.payload).forEach(([key, value]) => {
                    draft[key] = value;
                });
                break;

            case 'updateIndex':
                draft.index = action.payload;
                break;

            case 'handleFocus':
                draft.showList = action.payload.showList;
                if (action.payload.displayList !== undefined) {
                    draft.displayList = action.payload.displayList;
                }
                break;

            case 'updateSelectedValues':
                draft.selectedValues = action.payload;
                break;

            case 'update':
                Object.entries(action.payload).forEach(([key, value]) => {
                    draft[key] = value;
                });
                break;

            default:
                // if (window.devMode) throw new Error('reducer type not found :' + action.type);
                if (window.devMode) window.console.info('SelectInput1.stateReducer: type `' + action.type + '` not found');
                break;
        }
    });
};

function SelectInput1(props) {
    const [state, stateDispatch] = useReducer(stateReducer, {
        selectedValues: [],
        ...dataInitState,
    });

    useEffect(() => {
        updateStateSelectedValue(props);
    }, [props]);

    const listRef = useRef(null);
    const inputRef = useRef(null);

    const updateStateSelectedValue = async (localProps) => {
        let selectedValues = []; // populate from props
        if (localProps.isMultiple && Array.isArray(localProps.value) && localProps.value.length > 0 && localProps.value[0].val !== undefined) {
            selectedValues = localProps.value;
        } else if (localProps.isMultiple && localProps.data) {
            if (typeof localProps.value == 'string' && localProps.value.length > 0) {
                // convert string to array
                selectedValues = localProps.value.split(',');
            } else if (typeof localProps.value == 'object' && localProps.value.length > 0) {
                //array is an object
                selectedValues = localProps.value;
            }
            // convert the array to [{val,label}...]
            selectedValues = selectedValues.map((eSelected) => {
                if (localProps.customKey !== undefined) {
                    let update = Object.assign({}, eSelected);
                    update.val = update[localProps.customKey];
                    update.label = (() => {
                        const dataFind = localProps.data.filter((eData) => update[localProps.customKey] === eData[localProps.customKey]);
                        if (dataFind.length > 0) {
                            return dataFind[0].label;
                        } else {
                            return update[localProps.customKey];
                        }
                    })();
                    return update;
                } else {
                    return {
                        val: eSelected,
                        label: (() => {
                            const dataFind = localProps.data.filter((eData) => eSelected === eData.val);
                            if (dataFind.length > 0) {
                                return dataFind[0].label;
                            } else {
                                return eSelected;
                            }
                        })(),
                    };
                }
            });
        } else if (localProps.isMultiple && localProps.fillEndpoint && localProps.value && localProps.value.length > 0) {
            selectedValues = await apiFetch({
                action: localProps.fillEndpoint,
                ids: localProps.value,
            }).then((data) => {
                if (data.length === 0) return [];
                data.forEach((item) => {
                    item.type = TYPE_CURRENT;
                });
                return data;
            });
            // .then(data => (data.length > 0 ? data.map(e => ({ val: e[0], label: e[1] })) : []));
        }

        stateDispatch({ type: 'updateSelectedValues', payload: selectedValues }); // reset state to initial values
    };

    const updateSelectVal = (val, action = 'add') => {
        const { onChange, isMultiple, value } = props;
        // send data to props.onChange
        if (isMultiple) {
            //get array data
            let values = state.selectedValues.map((e) => {
                if (Object.keys(e).length > 2) {
                    //remove what was added for the selected values
                    e = Object.assign({}, e);
                    return e;
                } else {
                    return e.val;
                }
            });
            if (action === 'add') {
                // add value to array
                if (typeof val === 'object') {
                    val = Object.assign({ slug: cfSlug(val.label, '_'), type: TYPE_NEW }, val);
                }
                values.push(val);
            } else {
                // val is actually the index, not the object or object.val / id
                const index = val;
                val = values[index].val; // used for onChange
                if (values[index].type === TYPE_CURRENT) {
                    // already exists, mark it for delete
                    values[index].type = TYPE_REMOVED;
                } else {
                    // TYPE_NEW - added but not saved - remove value from array
                    values.splice(index, 1);
                }
            }
            //check how we send data: is sent like was received in props.value
            if (typeof value == 'object') {
                // we send data as array
                onChange(values, val, action);
            } else {
                // we send data as string
                onChange(values.toString(), val, action);
            }
        } else {
            // for single value
            onChange([val]);
        }
        handleReset();
    };

    const handleKeyUp = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        const { index, displayList } = state;
        const length = displayList.length;

        switch (evt.keyCode) {
            case 13:
                // Enter: pick current item
                if (displayList[index]) {
                    //if the dropdown is not open then 'displayList[index]' is undefined
                    // updateSelectVal(!props.customKey ? displayList[index].val : displayList[index]);
                    updateSelectVal(displayList[index]);
                }
                break;

            case 38: // ArrowUp: move up if not first
                if (index > 0) {
                    listRef.current.children[index - 1].scrollIntoView(false);
                    stateDispatch({ type: 'updateIndex', payload: index - 1 });
                }
                break;

            case 40: // ArrowDown: move down if not last
                if (index < length - 1) {
                    listRef.current.children[index + 1].scrollIntoView(false);
                    stateDispatch({ type: 'updateIndex', payload: index + 1 });
                }
                break;

            case 27: //esc
                handleBlur(evt);
                break;

            default:
                break;
        }
    };

    const handleReset = () => {
        inputRef.current.blur(); // focus out
        // inputRef.current.value = '';//reset inputValue
        stateDispatch({ type: 'update', payload: dataInitState }); // reset state to initial values
    };

    const updateStateDisplayList = (data, isMultiple, regex = false) => {
        return data.filter((e) => {
            //e = {val:'string|number',label:'string'}
            let isValueSelected = false;
            if (isMultiple) {
                //check if we should hide items from displayList
                isValueSelected = state.selectedValues.find((e2) => e2.val === e.val);
            }
            return !isValueSelected && (regex ? innerText(e.label).search(regex) > -1 : true);
        });
    };

    const handleFocus = () => {
        inputRef.current.focus(); // focus the input
        // check if should update the state
        if (!state.showList) {
            let stateUpdate = { showList: true }; //show the dropdown
            //check if display on focus displayList
            if (props.minLength === 0 && props.data !== undefined) {
                stateUpdate['displayList'] = updateStateDisplayList(props.data, props.isMultiple);
            }
            // this.setState(stateUpdate);
            stateDispatch({ type: 'handleFocus', payload: stateUpdate }); // reset state to initial values
        }
    };

    const handleBlur = (e) => {
        // hide list and reset inputValue when loose focus
        if (!e.currentTarget.contains(e.relatedTarget)) {
            handleReset();
        }
    };

    // update inputValue and displayList from where we choose an option
    const handleOnChange = async (e) => {
        // handleOnChange(){
        const { data, suggestEndpoint, minLength, isMultiple, allowAddOption } = props;

        // eventDelay( (()=>{ // used because one time was killed my CPU this event
        let dataUpdate = { inputValue: e.target.value, displayList: [] }; //update inputValue
        // let dataUpdate  = {inputValue:this.inputRef.current.value}; //update inputValue

        if (data !== undefined) {
            if (dataUpdate.inputValue.length >= minLength) {
                //update display list if minLength is passed

                const regexLocal = new RegExp(dataUpdate.inputValue, 'i');
                //show a displayList that match the input and skip value(s) selected
                dataUpdate['displayList'] = updateStateDisplayList(data, isMultiple, regexLocal);

                // insert on first position in list and check if not used already
                if (allowAddOption && !state.selectedValues.find((e) => e.val === dataUpdate['inputValue'])) {
                    dataUpdate['displayList'].unshift({
                        val: dataUpdate['inputValue'],
                        label: dataUpdate['inputValue'],
                    });
                }
            } else if (dataUpdate.inputValue.length === 0 && minLength === 0) {
                // reach this case when use backspace,delete..
                dataUpdate['displayList'] = updateStateDisplayList(data, isMultiple);
            }
            //end process , update the state
        } else if (suggestEndpoint !== undefined) {
            //update displayList using  suggestEndpoint
            if (dataUpdate.inputValue.length >= minLength) {
                //run a promise delay
                debounced()
                    .then(async () => {
                        stateDispatch({ type: 'update', payload: { isFetching: true } });
                        //when delay time had passed run it
                        const dataFetch = await apiFetch({
                            action: suggestEndpoint,
                            q: dataUpdate.inputValue,
                        }).then((data) => data);
                        // .then(data => (data.length > 0 ? data.map(e => ({ val: e[0], label: e[1] })) : []));
                        if (allowAddOption && !state.selectedValues.find((e) => e.slug === cfSlug(dataUpdate['inputValue'], '_'))) {
                            dataFetch.unshift({
                                val: 0,
                                label: dataUpdate['inputValue'],
                            });
                        }
                        dataUpdate['displayList'] = updateStateDisplayList(dataFetch, isMultiple);
                        dataUpdate['isFetching'] = false;
                        // this.setState(dataUpdate); // TODO
                        stateDispatch({ type: 'update', payload: dataUpdate });
                    })
                    .catch(() => {
                        //when delay time was cancel
                    });
                dataUpdate['isFetching'] = true;
            }
        }

        stateDispatch({ type: 'update', payload: dataUpdate });
    };

    // render
    const { className, minLength, isMultiple, multipleValuesLimit, value, placeholder } = props;
    const { displayList, showList, selectedValues, inputValue, isFetching } = state;

    let inputText = '';
    if (showList) {
        inputText = inputValue; // as typing ...
    } else if (!isMultiple) {
        inputText = value.length > 0 ? value[0].label : '';
    } else {
        inputText = inputValue; // whatever that might be
    }

    let inputPlaceholder = '';
    if (!isMultiple && value.length > 0) {
        if (value.constructor.name === 'String') {
            inputPlaceholder = value;
        } else if (value.constructor.name === 'Array') {
            inputPlaceholder = value[0].label;
        }
    } else {
        inputPlaceholder = placeholder;
    }
    // inputPlaceholder = !isMultiple && value.length > 0 ? value : placeholder;
    // window.console.log('SelectInput1/render/inputValue', { inputText, inputValue, placeholder, showList, value }); // @debug

    return (
        <div className="modul-react-select-input" tabIndex={0} onBlur={handleBlur}>
            <div className={classnames('select-control', className)}>
                {selectedValues.length > 0 && ( //selected multiple values
                    <div className="multiple-select-list">
                        {selectedValues.map((e, idx) => {
                            if (e.type !== TYPE_REMOVED) {
                                return (
                                    <div className="multiple-value" key={e.val}>
                                        {e.label}
                                        <span
                                            onClick={() => updateSelectVal(idx, 'remove')} //remove one value from the selected multiple values
                                            className="remove-value"
                                        >
                                            <i className="far fa-times mx-1" />
                                        </span>
                                    </div>
                                );
                            } else return null;
                        })}
                    </div>
                )}

                <input
                    ref={inputRef}
                    value={inputText}
                    placeholder={inputPlaceholder}
                    onChange={handleOnChange}
                    onKeyUp={handleKeyUp}
                    onFocus={handleFocus}
                    className="form-control"
                    disabled={isMultiple && multipleValuesLimit > 0 && selectedValues.length === multipleValuesLimit}
                    // onBlur={}//can't be used here because can't reach event onClick for option selected
                />
            </div>
            {isMultiple && multipleValuesLimit > 0 && selectedValues.length === multipleValuesLimit && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                    You have reached the maximum allowed number of items
                </div>
            )}
            {showList && (
                <ul ref={listRef}>
                    {minLength && inputValue.length < minLength ? (
                        <li className="initial">{minLength} chars is required</li>
                    ) : isFetching ? (
                        <li>Loading ...</li>
                    ) : displayList.length === 0 ? (
                        <li>No option</li>
                    ) : (
                        displayList.map((e, i) => {
                            return (
                                <li
                                    key={i}
                                    className={state.index === i ? 'active' : ''}
                                    onMouseEnter={() => stateDispatch({ type: 'updateIndex', payload: i })}
                                    onClick={() => updateSelectVal(e, 'add')}
                                >
                                    {e.label}
                                </li>
                            );
                        })
                    )}
                </ul>
            )}
        </div>
    );
}
SelectInput1.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                val: PropTypes.number,
                label: PropTypes.string,
            })
        ),
    ]),
    className: PropTypes.string,
    minLength: PropTypes.number, // min chars to start search for items to populate the list
    isMultiple: PropTypes.bool,
    multipleValuesLimit: PropTypes.number,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired, //will receive the value/values selected
    allowAddOption: PropTypes.bool,
    // data: PropTypes.oneOfType([PropTypes.array]), // [{val:'string|number',label:'string'}]
    data: PropTypes.arrayOf(
        PropTypes.shape({
            val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    fillEndpoint: PropTypes.string,
    suggestEndpoint: PropTypes.string,
    customKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // to custom populate state.selectedValues[x].val,
};

export default SelectInput1;
