import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import variables from 'scss/base/_variables.module.scss';
import { DocumentsContext } from '_dash/Contexts';
import { useSelector } from 'react-redux';

export default function DocumentsLimit() {
    const { docs } = useContext(DocumentsContext);
    const maxSize = useSelector((state) => state.company.planDocumentsLimit); //MB;

    const percentage = (partialValue, totalValue) => {
        return ((100 * partialValue) / totalValue).toFixed(2);
    };
    const handleProgressBar = () => {
        var currentSize = 0;

        docs.map((item) => {
            item.docs.forEach((item1) => {
                currentSize += item1.size;
            });
        });
        //convert in MB size
        const currentSizeMB = (currentSize / 1024 / 1024).toFixed(2);

        if (currentSizeMB > maxSize) {
            document.getElementById('error_bar').innerHTML = "<i class='fal fa-exclamation-triangle'></i> Your files exceed 1000 Mb ";
            document.getElementById('fill_bar').style.width = `100%`;
            document.getElementById('fill_bar').innerHTML = '<span>100%</span>';
            return false;
        } else {
            let percentajValue = percentage(currentSizeMB, maxSize);
            let incompleteValue = (100 - percentajValue).toFixed(2);

            document.getElementById('fill_bar').style.width = `${percentajValue}%`;
            document.getElementById('fill_bar').innerHTML = document.getElementById('fill_bar').clientWidth > 55 ? '<span>' + `${percentajValue}` + '%</span>' : '';
            document.getElementById('incomplete_bar').innerHTML = '<span>' + `${incompleteValue}` + '%</span>';
            //document.getElementById('incomplete_bar').style.width = `${incompleteValue}%`;
        }
    };

    useEffect(() => {
        handleProgressBar();
        return () => {};
    }, [docs]);

    return (
        <DocsLimitSt>
            <div className="percentage-bar">
                <div className="fill" id="fill_bar" />
                <div className="incomplete" id="incomplete_bar" />
            </div>
            <p className="text-right">
                Total Size: <span>{maxSize} MB</span>
            </p>
            <p className="text-right errorBar" id="error_bar" />
        </DocsLimitSt>
    );
}

const DocsLimitSt = styled.div`
    margin-bottom: 0;
    display: flex;
    flex-flow: row wrap;
    p {
        max-width: 100%;
        flex: 0 0 100%;
        font-size: 13px;
        span {
            color: ${variables.colorBase};
            font-size: 14px;
            font-weight: 500;
        }
    }
    .percentage-bar {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        height: 35px;
        border: 1px solid ${variables.colorPrimary};
        border-radius: 10px;
        overflow: hidden;
        > div {
            display: flex;
            align-items: center;
            padding: 0;
            span {
                padding: 0 20px;
            }
        }
        .fill {
            background-color: ${variables.colorSecondary};
            color: white;
            transition: all 0.3s;
            white-space: nowrap;
            border-bottom-left-radius: 9px;
            border-top-left-radius: 9px;
            width: 0%;
        }
        .incomplete {
            span {
                color: ${variables.colorBase};
            }
        }
    }
    .errorBar {
        color: ${variables.colorRed};
        font-size: 15px;
        font-weight: 500;
        i {
            margin-right: 5px;
            font-size: 16px;
        }
    }
`;
