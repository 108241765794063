import React from 'react';
import PropTypes from 'prop-types';
import { FieldsContext } from './context';
import debounce from 'modules/debounce';
import Spinner from 'modules/react-spinner';
import { youtubeUrlParser, vimeoUrlParser } from '_dash/components/util';
import ClassNames from 'classnames';
import differ from 'deep-diff';
import staticData from 'json/staticData';
import { getTopOffset } from '_dash/components/util';
import { isStrongPassword } from './Field';
import { isEqual } from 'lodash';

//used for handleChange to have delay on fetch
const debounced = debounce(() => new Promise((resolve) => resolve()), 500);

export default class Fields extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMultiple = this.handleChangeMultiple.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.useCustomSubmit = this.useCustomSubmit.bind(this);
        this.updateIsSaved = this.updateIsSaved.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fieldsChildStateValidate = this.fieldsChildStateValidate.bind(this);
        this.updateState = this.updateState.bind(this);
        this.getFinalValues = this.getFinalValues.bind(this);

        this.state = {
            errors: {},
            schema: {},
            isValidating: false,
            foundRenderSubmit: false,
            handleChangeMultiple: this.handleChangeMultiple,
            handleChange: this.handleChange, //`this.context` from childs forced me to put here in order to work
            handleSubmit: this.handleSubmit, //`this.context` from childs forced me to put here in order to work
            validateForm: this.validateForm,
            useCustomSubmit: this.useCustomSubmit,
            updateIsSaved: this.updateIsSaved,
            fieldsChildStateValidate: this.fieldsChildStateValidate,
            updateState: this.updateState,
            ...this.getDataFromPropInitialValues(props.initialValues),
        };

        this.doneSubmit = this.doneSubmit.bind(this);
        this.updateFieldMsg = this.updateFieldMsg.bind(this);
        this.getValidationMsg = this.getValidationMsg.bind(this);
    }

    updateState(handleState) {
        this.setState(handleState);
    }

    // convert initial values to key/value pairs
    getDataFromPropInitialValues = (propInitialValues) => {
        let values = {};
        let groups = {};
        Object.keys(propInitialValues).forEach((key) => {
            //check if data array
            if (
                Array.isArray(propInitialValues[key]) &&
                (this.props.valuesKeyObject === undefined || (this.props.valuesKeyObject !== undefined && this.props.valuesKeyObject.indexOf(key) === -1))
            ) {
                if (propInitialValues[key].length > 0 && typeof propInitialValues[key][0].id != 'undefined') {
                    groups[key] = propInitialValues[key].length;
                    propInitialValues[key].forEach((e, i) => {
                        Object.keys(e).forEach((key2) => {
                            values[key + '[' + i + ']' + key2] = e[key2];
                        });
                    });
                } else {
                    values[key] = propInitialValues[key].filter(() => true);
                }
            } else {
                values[key] = propInitialValues[key];
            }
        });

        return {
            values: values,
            groups: groups, //used in FieldArray
            initialValues: propInitialValues,
            isSaved: JSON.stringify(propInitialValues) === '{}' ? false : true,
            isSubmitting: false,
        };
    };

    testFileSizeLimit = (fileItem, fileSizeLimit) => {
        const fileMimeType = fileItem.type.split('/');
        // fileSize = fileItem.size;
        const displayVal = ('' + fileItem.size / 1024 / 1024).split('.'); // turn to string and split by .
        if (displayVal.length === 2) {
            // has decimals
            displayVal[1] = displayVal[1].slice(0, 2); // trim decimals to only 2
        }

        switch (typeof fileSizeLimit) {
            case 'object':
                if (fileSizeLimit[fileItem.type]) {
                    // first look for specific mimeType/Subtype rule
                    if (parseInt(fileSizeLimit[fileItem.type]) < fileItem.size / 1024 / 1024) {
                        return (
                            <span>
                                Your {fileItem.type} file <em>{fileItem.name}</em> has size <strong>{displayVal.join('.')}MB</strong> greater than the{' '}
                                <strong>{fileSizeLimit[fileItem.type]}MB</strong> limit.
                            </span>
                        );
                    }
                } else if (fileSizeLimit[fileMimeType[0]]) {
                    // then look for the more generic mimeType ONLY rule
                    if (parseInt(fileSizeLimit[fileMimeType[0]]) < fileItem.size / 1024 / 1024) {
                        return (
                            <span>
                                Your {fileMimeType[0]} file <em>{fileItem.name}</em> has size <strong>{displayVal.join('.')}MB</strong> greater than the{' '}
                                <strong>{fileSizeLimit[fileMimeType[0]]}MB</strong> limit.
                            </span>
                        );
                    }
                } else if (fileSizeLimit.all) {
                    // look for the `all` rule
                    if (parseInt(fileSizeLimit.all) < fileItem.size / 1024 / 1024) {
                        return (
                            <span>
                                Your file <em>{fileItem.name}</em> has size <strong>{displayVal.join('.')}MB</strong> greater than the <strong>{fileSizeLimit.all}MB</strong> limit.
                            </span>
                        );
                    }
                }
                break;

            default:
                // number
                if (parseInt(fileSizeLimit) < fileItem.size / 1024 / 1024) {
                    return (
                        <span>
                            Your file <em>{fileItem.name}</em> has size <strong>{displayVal.join('.')}MB</strong> greater than the <strong>{fileSizeLimit}MB</strong> limit.
                        </span>
                    );
                }
                break;
        }
    };

    componentDidUpdate(prevProps) {
        // update state when props initialValues change
        // if (prevProps.initialValues !== this.props.initialValues) {
        if (differ(prevProps.initialValues, this.props.initialValues) !== undefined) {
            this.setState((prevState) => ({ ...prevState, ...this.getDataFromPropInitialValues(this.props.initialValues) }));
            this.forceUpdate();

            // also update ref value for visual effect for input, textarea
            // case fixed in Field.jsx and not made here
        }
        // console.log("Context state updated:", this.state.values);
    }

    useCustomSubmit() {
        if (!this.state.foundRenderSubmit) {
            this.setState((prevState) => ({ ...prevState, foundRenderSubmit: true }));
        }
    }

    /**
     * @param {object} finalValues use when the props are not updated from outside if is empty then stop the loading button
     * @param {object} errors might come from server
     */
    doneSubmit(finalValues = {}, errors = {}) {
        if (Object.keys(finalValues).length === 0) {
            this.setState(
                (prevState) => ({ ...prevState, isSubmitting: false, errors: errors }),
                () => {
                    if (Object.keys(errors).length > 0) {
                        this.errorFocus(errors);
                    }
                }
            );
        } else {
            this.setState(
                (prevState) => ({ ...prevState, ...this.getDataFromPropInitialValues(finalValues) }),
                () => {
                    if (this.state.fieldsArray) {
                        Object.keys(this.state.fieldsArray).map((key) => this.state.fieldsArray[key]());
                    }
                }
            );
        }
    }

    errorFocus = (errors) => {
        // console.log('errors focus :', errors)
        for (const key in errors) {
            //if (window.devMode) window.console.log('error', errors[key]); // @debug
            // console.log('schema key: ', key, this.state.schema[key])
            if (this.state.schema[key] === undefined) {
                window.swal({ type: 'warning', title: key, text: errors[key] });
            } else if (this.state.schema[key].ref) {
                switch (this.state.schema[key].type) {
                    case 'selectInput':
                        this.state.schema[key].ref.inputRef.current.focus();
                        window.scroll({
                            top: getTopOffset(this.state.schema[key].ref.inputRef.current),
                            left: 0,
                            behavior: 'smooth',
                        });
                        break;
                    case 'texteditor':
                        this.state.schema[key].ref.refs.editor.editor.focus();
                        window.scrollTo(0, window.scrollY - 140);
                        break;
                    default:
                        this.state.schema[key].ref.focus();
                        window.scroll({
                            top: getTopOffset(this.state.schema[key].ref),
                            left: 0,
                            behavior: 'smooth',
                        });
                        break;
                }
                // window.scrollTo(0, window.scrollY - 140);
                break;
                //Finds y value of given object
                /*function findPos(obj) {
                            var curTop = 0;
                            if (obj.offsetParent) {
                                do {
                                    curTop += obj.offsetTop;
                                } while (obj = obj.offsetParent);
                                console.log('curTop=====' + curTop);
                                return [curTop];
                            }
                        }

                        window.scroll(0, findPos(document.getElementById("divFirst")));*/
            } else if (this.state.schema[key].containerRef) {
                window.scroll({
                    top: getTopOffset(this.state.schema[key].containerRef),
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    /**
     * Validates a form / displays error messages
     * @param {Function} tempSubmitFunc - optional function to be executed (probably submit) if form is valid
     * @returns {object}
     */
    async validateForm(tempSubmitFunc) {
        let errors = {};
        // do normal validation
        for (var key in this.state.schema) {
            const msg = this.getValidationMsg(key);
            if (msg) {
                errors[key] = msg;
            }
        }

        // check for async validation if have in schema
        //TODO: is await just for one asyncFn, to be updated to loop over an async iterable object
        for (key in this.state.schema) {
            // just run asyncFn if not already have error above
            if (!errors[key]) {
                const { rules } = this.state.schema[key];

                if (rules && rules.customAsyncFn) {
                    // get async msg
                    const msg = await rules.customAsyncFn(this.state.values[key]);
                    if (msg) {
                        errors[key] = msg;
                    }
                }
            }
        }

        // if got errors display them and stop submitting
        if (Object.keys(errors).length > 0) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    errors: errors,
                    isValidating: false,
                })
                // propose something for errors to be more visible
                //,() => window.swal({type:'warning', text:<pre>{JSON.stringify(errors,null, 2)}</pre>} )
            );
            this.errorFocus(errors);
        } else if (typeof tempSubmitFunc === 'function') {
            // forward the values to be submitted
            // start submitting
            this.setState((prevState) => {
                if (!prevState.isSubmitting) return { ...prevState, isSubmitting: true, isValidating: false };
            });
            const execResult = await tempSubmitFunc(Object.assign({}, this.getFinalValues(this.state.values)), this.doneSubmit); // do submit action
            /* this.setState(
                 prevState => {
                    if (!prevState.isSubmitting) return { isSubmitting: true, isValidating: false };
                },
                () => {
                    execResult = tempSubmitFunc(Object.assign({}, this.getFinalValues(this.state.values)), this.doneSubmit); // do submit action
                }
            ); */
            return { errors: errors, result: execResult };
        }

        return { errors: errors };
    }

    async fieldsChildStateValidate() {
        await this.handleSubmit();
        return new Promise((resolve, reject) => {
            if (Object.keys(this.state.errors).length) {
                resolve(false);
            } else {
                resolve(true);
            }
        });
    }

    /**
     *
     * @param {*} e HTML Element
     * @param {*} extraData used by <Submit /> and when need more then one submit button example `components/job/AddEdit.jsx
     */
    async handleSubmit(e, extraData = {}, callbackFn = undefined) {
        if (e) {
            e.preventDefault();
        }

        if (!this.state.isValidating) {
            //submit only if form validation is done
            // mark begins validating
            this.setState(
                (prevState) => {
                    if (!prevState.isValidating) return { ...prevState, isValidating: true };
                },
                async () => {
                    let errors = {};
                    // do normal validation
                    for (var key in this.state.schema) {
                        if (this.state.schema[key].type === 'fields') {
                            // is an array of functions
                            for (const index in this.state.schema[key].fieldsChilds) {
                                const isFieldsChildValid = await this.state.schema[key].fieldsChilds[index]();
                                if (!isFieldsChildValid) {
                                    errors[key] = key + ' child fields are not valid!';
                                    // break;
                                    // this.setState({ isValidating: false });
                                    // return false;
                                }
                            }
                        }
                        const msg = this.getValidationMsg(key);
                        if (msg) {
                            errors[key] = msg;
                        }
                    }

                    // check for async validation if have in schema
                    //TODO: is await just for one asyncFn, to be updated to loop over an async iterable object
                    for (key in this.state.schema) {
                        // just run asyncFn if not already have error above
                        if (!errors[key]) {
                            const { rules } = this.state.schema[key];

                            if (rules && rules.customAsyncFn) {
                                // get async msg
                                const msg = await rules.customAsyncFn(this.state.values[key]);
                                if (msg) {
                                    errors[key] = msg;
                                }
                            }
                        }
                    }

                    // if got errors display them and stop submitting
                    if (Object.keys(errors).length) {
                        this.setState(
                            (prevState) => ({
                                ...prevState,
                                errors: errors,
                                isValidating: false,
                            })
                            // propose something for errors to be more visible
                            //,() => window.swal({type:'warning', text:<pre>{JSON.stringify(errors,null, 2)}</pre>} )
                        );
                        this.errorFocus(errors);
                    } else {
                        // forward the values to be submitted
                        // start submitting
                        this.setState(
                            (prevState) => {
                                if (!prevState.isSubmitting) return { ...prevState, isSubmitting: true, isValidating: false };
                            },
                            () => {
                                const { values } = this.state;
                                const finalValues = this.getFinalValues(values);
                                const submitValues = Object.assign({}, finalValues, extraData);
                                // if (window.devMode) window.console.log('@temp Fields/handleSubmit/finalValues', submitValues); // @debug
                                this.props.onSubmit(submitValues, this.doneSubmit, this.state.isSaved, callbackFn); //do submit action
                            }
                        );
                    }
                }
            );
        }
    }

    getFinalValues(values) {
        const objDiffValues = {};
        // console.log({ values: cloneDeep(values) })
        // update values if have groups
        Object.keys(values).forEach((key) => {
            if (key.search(/\[/) >= 0) {
                let keyArrayFirst = key.split('[');
                let keyArraySecond = keyArrayFirst[1].split(']');
                let key1 = keyArrayFirst[0];
                let index = keyArraySecond[0];
                let key2 = keyArraySecond[1];
                // check if should create a empty array
                if (typeof objDiffValues[key1] == 'undefined') {
                    objDiffValues[key1] = [];
                }
                // check if index exist
                if (typeof objDiffValues[key1][index] == 'undefined') {
                    objDiffValues[key1][index] = {};
                }
                objDiffValues[key1][index][key2] = values[key];
            } else {
                // filter multiUpload data to get only
                if (this.state.schema[key] && this.state.schema[key].type === 'multipleUpload' && values[key]) {
                    objDiffValues[key] = values[key].filter((f) => f.constructor.name === 'File');
                } else {
                    objDiffValues[key] = values[key];
                }
            }
        });
        // if (window.devMode) window.console.log('getFinalValues', finalValues, this.props.initialValues.id); // @debug
        if (this.props.initialValues.id !== undefined && parseInt(this.props.initialValues.id) > 0) {
            return this.diff(this.props.initialValues, objDiffValues);
        } else {
            return objDiffValues;
        }
    }
    // note: this method escape the fields that end  with id and are type numeric, need some fields to be send all time in HQL methods used to check the parent
    diff(initial, updated) {
        let diffObject = {};

        Object.keys(updated).forEach((key) => {
            if (key.slice(-2).toLowerCase() === 'id' && typeof updated[key] === 'number') {
                // includes all fields that use id in it's name and are numeric are used as conditions in orm
                diffObject[key] = updated[key];
            } else if (!(updated[key] instanceof File) && typeof updated[key] === 'object' && !Array.isArray(updated[key]) && initial[key] === undefined) {
                // if have inner objects with keys parse also inner
                diffObject[key] = this.diff(initial[key], updated[key]);
            } else if (JSON.stringify(updated[key]) !== JSON.stringify(initial[key])) {
                // now get what is diff only
                diffObject[key] = updated[key];
            }
        });
        return diffObject;
    }

    updateIsSaved() {
        this.setState((prevState) => ({ ...prevState, isSaved: this.handleChangeIsSaved(this.state.initialValues, this.state.values) }));
    }

    handleChangeIsSaved(initialValues, values) {
        return Object.keys(initialValues).length > 0 && isEqual(initialValues, values);
    }

    handleChangeMultiple(data) {
        // if (window.devMode) window.console.log('Fields/handleChangeMultiple/data', data); // @debug
        const { submitOnChange } = this.props;
        this.setState(
            (prevState) => {
                const newValues = {
                    ...prevState.values,
                    ...data,
                };
                return {
                    ...prevState,
                    values: newValues,
                    isSaved: this.handleChangeIsSaved(prevState.initialValues, newValues),
                };
            },
            // run field validation
            () => {
                // if (window.devMode) window.console.log('Fields/handleChangeMultiple/callback', this.state.values); // @debug
                const dataEntries = Object.entries(data);
                for (let i in dataEntries) {
                    const [key, val] = dataEntries[i];
                    // if (window.devMode) window.console.log('Fields/handleChangeMultiple/key-val', key, val); // @debug
                    if (val.constructor.name === 'File') {
                        if (val.size > staticData.maxUploadSize.general * 1024 * 1024) {
                            this.updateFieldMsg(key, 'Sorry, your file size is greater than the ' + staticData.maxUploadSize.general + 'MB limit');
                        }
                    }
                    if (this.state.schema[key] && this.state.schema[key].rules) {
                        //check if we have rules to validate
                        //do normal validation
                        const msg = this.getValidationMsg(key);
                        if (msg) {
                            this.updateFieldMsg(key, msg);
                            //if no error on 1st validation do promise validation if has `customAsyncFn`
                        } else if (this.state.schema[key].rules.customAsyncFn) {
                            // display a msg because might take time until finish the async
                            this.updateFieldMsg(key, 'validating ...');

                            //run a promise delay
                            debounced()
                                .then(() => {
                                    //when delay time had passed run it
                                    this.state.schema[key].rules.customAsyncFn(val).then((rez) => this.updateFieldMsg(key, rez));
                                })
                                .catch(() => {
                                    //when delay time was cancel
                                    //this.updateFieldMsg(key,'validating ...'); // moved above
                                });
                        } else {
                            // no validation msg
                            this.updateFieldMsg(key);
                        }
                    }
                } // end for
                // check if should submit if no validation exist
                if (
                    (typeof submitOnChange === 'boolean' && submitOnChange === true) ||
                    (submitOnChange.constructor === Array &&
                        // look if the changed key is in the array of submitOnChange array
                        // was the changed key part of the submitOnChange array?
                        // (submitOnChange.constructor === Array && submitOnChange.findIndex((item) => item === key) >= 0)
                        submitOnChange.findIndex((item) => data[item] !== undefined) >= 0)
                ) {
                    this.handleSubmit();
                }
            }
        );
    }

    //key= attribute name of input tag
    //val=the value of input tag
    handleChange(key, val) {
        const { submitOnChange } = this.props;
        //update value
        this.setState(
            (prevState) => {
                // case fixed in Field.jsx and commented here
                // also update ref value for visual effect for input, textarea
                // if (prevState.schema[key].ref && ['HTMLInputElement', 'HTMLTextAreaElement'].indexOf(prevState.schema[key].ref.constructor.name) > -1) {
                //     prevState.schema[key].ref.value = val;
                // }
                const newValues = {
                    ...prevState.values,
                    [key]: val,
                };
                return {
                    ...prevState,
                    values: newValues,
                    isSaved: this.handleChangeIsSaved(prevState.initialValues, newValues),
                };
            },
            // run field validation
            () => {
                if (val?.constructor.name === 'File') {
                    if (val.size > staticData.maxUploadSize.general * 1024 * 1024) {
                        this.updateFieldMsg(key, 'Sorry, your file size is greater than the ' + staticData.maxUploadSize.general + 'MB limit');
                    }
                } else if (val?.constructor.name === 'FileList') {
                    let fileListSize = 0;
                    for (let fileItem of val) {
                        fileListSize += fileItem.size;
                    }
                    if (fileListSize > staticData.maxUploadSize.general * 1024 * 1024) {
                        this.updateFieldMsg(key, 'Sorry, your files total size is greater than the ' + staticData.maxUploadSize.general + 'MB limit');
                    }
                }
                if (this.state.schema[key] && this.state.schema[key].rules) {
                    //check if we have rules to validate
                    //do normal validation
                    const msg = this.getValidationMsg(key);
                    if (msg) {
                        this.updateFieldMsg(key, msg);
                        //if no error on 1st validation do promise validation if has `customAsyncFn`
                    } else if (this.state.schema[key].rules.customAsyncFn) {
                        // display a msg because might take time until finish the async
                        this.updateFieldMsg(key, 'validating ...');

                        //run a promise delay
                        debounced()
                            .then(() => {
                                //when delay time had passed run it
                                this.state.schema[key].rules.customAsyncFn(val).then((rez) => this.updateFieldMsg(key, rez));
                            })
                            .catch(() => {
                                //when delay time was cancel
                                //this.updateFieldMsg(key,'validating ...'); // moved above
                            });
                    } else {
                        // no validation msg
                        this.updateFieldMsg(key);
                    }
                } else {
                    // check if should submit if no validation exist
                    if (
                        (typeof submitOnChange === 'boolean' && submitOnChange === true) ||
                        (submitOnChange.constructor === Array && submitOnChange.findIndex((item) => item === key) >= 0)
                    ) {
                        this.handleSubmit();
                    }
                }
            }
        );
    }

    //msg - if missing then we remove the error for that key
    updateFieldMsg = (key, msg) => {
        const { submitOnChange } = this.props;
        if (msg) {
            if (!this.state.errors[key] || this.state.errors[key] !== msg) {
                //check if should update the state
                //update error field when error don't exist or is different
                this.setState((prevState) => ({
                    ...prevState,
                    errors: Object.assign({}, prevState.errors, { [key]: msg }),
                }));
            }
        } else {
            //remove field error if exist because don't have error msg
            if (this.state.errors[key]) {
                this.setState(
                    (prevState) => {
                        /*let newStateErrors = Object.assign({}, prevState.errors);
                        delete newStateErrors[key];*/
                        return {
                            ...prevState,
                            // errors: newStateErrors,
                            errors: Object.assign({}, prevState.errors, { [key]: undefined }),
                        };
                    },
                    () => {
                        // check if should submit after validation
                        if (
                            (typeof submitOnChange === 'boolean' && submitOnChange === true) ||
                            (submitOnChange.constructor === Array && submitOnChange.findIndex((item) => item === key) >= 0)
                        ) {
                            this.handleSubmit();
                        }
                    }
                );
            } else {
                // check if should submit if no error found
                if (
                    (typeof submitOnChange === 'boolean' && submitOnChange === true) ||
                    (submitOnChange.constructor === Array && submitOnChange.findIndex((item) => item === key) >= 0)
                ) {
                    this.handleSubmit();
                }
            }
        }
    };

    getValidationMsg(key) {
        // get the value on which we check the rules
        const val = this.state.values[key];
        const { rules } = this.state.schema[key];
        for (var ruleName in rules) {
            switch (ruleName) {
                case 'required':
                    if (rules.required !== undefined && rules.required !== false && rules.required !== 0) {
                        if (val === null || val === undefined || val === '' || val === 0 || (val.constructor.name === 'Array' && val.length === 0)) {
                            // return  'Please fill out '+ (this.state.schema[key].label?this.state.schema[key].label:'this') +' field';
                            if (typeof rules.required === 'string' && rules.required.length > 0) {
                                //  && rules.required !== 1
                                return rules.required;
                            } else {
                                switch (this.state.schema[key].type) {
                                    case 'radio':
                                    case 'select':
                                        return 'Please choose one option';
                                    case 'checkbox':
                                        return 'Please check this box';
                                    case 'multipleCheckbox':
                                        return 'Please check at least one checkbox';
                                    default:
                                        return 'Please fill out this field';
                                }
                            }
                        }
                    }
                    break;
                case 'minLength':
                    {
                        const minLength = rules.minLength;
                        if (val.length < minLength) return 'Please use at least ' + minLength + ' characters (you are currently using ' + val.length + ')';
                    }
                    break;
                /*
                case 'useSpecialChars':
                    {
                        const specialChars = `\`!@#$%^&*()_+-='"<>?{},./`;
                        const format = new RegExp(`[${specialChars}]`);
                        if (!format.test(val)) {
                            return 'Use at least one special char from these ones: ' + specialChars;
                        }
                    }
                    break;
                case 'useNumber':
                    if (!/\d/.test(val)) {
                        return 'Use at least one number';
                    }
                    break;
                case 'useUpperCase':
                    if (!/[A-Z]/.test(val)) {
                        return 'Use at least one uppercase letter';
                    }
                    break;
                */
                case 'maxLength':
                    {
                        const maxLength = rules.maxLength;
                        // if (val.length > maxLength) return 'You are allowed only ' + maxLength + ' characters (you are currently using ' + val.length + ' characters).';
                        switch (val.constructor.name) {
                            case 'Array':
                                if (val.length > maxLength)
                                    // return 'You are allowed only ' + maxLength + ' options (you are currently using ' + val.length + ')';
                                    return (
                                        <div>
                                            You are allowed only <strong>{maxLength}</strong> options <em>(you are currently using {val.length})</em>
                                        </div>
                                    );
                                break;

                            case 'String':
                            default:
                                if (val.length > maxLength)
                                    // return 'You are allowed only ' + maxLength + ' characters (you are currently using ' + val.length + ')';
                                    return (
                                        <div>
                                            You are allowed only <strong>{maxLength}</strong> characters <em>(you are currently using {val.length})</em>
                                        </div>
                                    );
                                break;
                        }
                    }
                    break;
                case 'unsignedFloat':
                    if (!val.match(/^[(^\d{1,6}\.?\d{1,6}$)]{1,7}$/)) return 'Up to 6 numbers and one decimal point can be used.';
                    break;
                case 'strongPassword':
                    if (!isStrongPassword(val)) return 'Password must be at least 8 characters including, lowercase, uppercase, symbols and numbers';
                    break;
                case 'email':
                    /*
                     * old version: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                     * however removed the max 4 for the email domain extension
                     * because of custom domain extensions i.e. `global`
                     */
                    if (val && !val.match(/^[\w-.']+@([\w-]+\.)+[\w-]{2,}$/g)) {
                        return 'Please enter a valid email address';
                    }
                    break;
                case 'url':
                    if (
                        val &&
                        !val.match(
                            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g
                        )
                    ) {
                        return 'Please enter a valid URL';
                    }
                    break;
                case 'maxFilesCount':
                    if (val !== undefined) {
                        if (val.constructor.name === 'FileList' || val.constructor.name === 'Array') {
                            if (val.length > parseInt(rules.maxFilesCount)) {
                                return (
                                    <span>
                                        Sorry, you've reached the maximum number of files allowed for upload: <strong>{rules.maxFilesCount}</strong>
                                    </span>
                                );
                            }
                        }
                    }
                    break;
                case 'fileTotalMaxMbSize': // only for multi files upload
                    if (val !== undefined) {
                        if (val.constructor.name === 'FileList') {
                            let fileItem,
                                fileSize = 0;
                            for (fileItem of val) {
                                fileSize += fileItem.size;
                            }
                            if (parseInt(rules.fileTotalMaxMbSize) < fileSize / 1024 / 1024) {
                                const displayVal = ('' + fileSize / 1024 / 1024).split('.'); // turn to string and split by .
                                if (displayVal.length === 2) {
                                    // has decimals
                                    displayVal[1] = displayVal[1].slice(0, 2); // trim decimals to only 2
                                }
                                return (
                                    <span>
                                        Your total file size <strong>{displayVal.join('.')}MB</strong> is larger than the <strong>{rules.fileTotalMaxMbSize}MB</strong> limit.
                                    </span>
                                );
                            }
                        }
                    }
                    break;
                case 'fileMaxMbSize':
                    if (val !== undefined) {
                        let validationMsg = null;

                        switch (val.constructor.name) {
                            case 'FileList': // multi file, test each one for max size, not their total size
                            case 'Array':
                                for (let fileItem of val) {
                                    if (fileItem.constructor.name !== 'File') continue; // skip non-sense
                                    // if (window.devMode) window.console.log('fileItem', fileItem); // @debug

                                    validationMsg = this.testFileSizeLimit(
                                        fileItem, // for type, size
                                        rules.fileMaxMbSize // fileSizeLimit
                                    );
                                    if (validationMsg !== undefined && validationMsg !== null) {
                                        return validationMsg;
                                    }
                                }
                                break;

                            case 'String':
                                const valBlob = new Blob([val]);

                                validationMsg = this.testFileSizeLimit(
                                    valBlob, // for type, size
                                    rules.fileMaxMbSize // fileSizeLimit
                                );
                                if (validationMsg !== undefined) {
                                    return validationMsg;
                                }

                                /* fileSize = valBlob.size;
                                if (parseInt(rules.fileMaxMbSize) < fileSize / 1024 / 1024) {
                                    displayVal = ('' + fileSize / 1024 / 1024).split('.'); // turn to string and split by .
                                    if (displayVal.length === 2) {
                                        // has decimals
                                        displayVal[1] = displayVal[1].slice(0, 2); // trim decimals to only 2
                                    }
                                    return (
                                        <span>
                                            Your file size <strong>{displayVal.join('.')}MB</strong> is larger than the <strong>{rules.fileMaxMbSize}MB</strong> limit.
                                        </span>
                                    );
                                } */
                                break;

                            case 'File': // single file
                            case 'Blob':
                            default:
                                validationMsg = this.testFileSizeLimit(
                                    val, // for type, size
                                    rules.fileMaxMbSize // fileSizeLimit
                                );
                                if (validationMsg !== undefined) {
                                    return validationMsg;
                                }
                                // const fileBlob = val.slice();
                                // fileSize = val.size;
                                // if (parseInt(rules.fileMaxMbSize) < fileBlob.size / 1024 / 1024) {
                                /* if (parseInt(rules.fileMaxMbSize) < fileSize / 1024 / 1024) {
                                    displayVal = ('' + fileSize / 1024 / 1024).split('.'); // turn to string and split by .
                                    if (displayVal.length === 2) {
                                        // has decimals
                                        displayVal[1] = displayVal[1].slice(0, 2); // trim decimals to only 2
                                    }
                                    return (
                                        <span>
                                            Your file size <strong>{displayVal.join('.')}MB</strong> is larger than the <strong>{rules.fileMaxMbSize}MB</strong> limit.
                                        </span>
                                    );
                                } */
                                break;
                        }
                    }
                    break;
                case 'fileExtensionAccept':
                    if (val !== undefined) {
                        // eslint-disable-next-line default-case
                        switch (val.constructor.name) {
                            case 'Array': //array of Files
                                for (const file of val) {
                                    if (file.constructor.name !== 'File') continue;
                                    const checkFileExt = fileExtensionAccept({ file, fileExtensionAccept: rules.fileExtensionAccept });
                                    if (checkFileExt) return checkFileExt;
                                }
                                break;
                            case 'File':
                                return fileExtensionAccept({ file: val, fileExtensionAccept: rules.fileExtensionAccept });
                        }
                    }
                    break;
                case 'fileAccept': {
                    if (val !== undefined && val.type !== undefined) {
                        const filetMimeTypeParts = val.type.split('/');
                        let isValidType = false;
                        let isValidExt = false;
                        rules.fileAccept.forEach((e) => {
                            if (e.type === filetMimeTypeParts[0]) {
                                isValidType = true;
                                isValidExt = e.ext.split(',').some((ext) => ext === '*' || ext === filetMimeTypeParts[1]);
                                // also check if .ext from name is valid as last option
                                if (!isValidExt) {
                                    const fileNameParts = val.name.split('.');
                                    isValidExt = e.ext.split(',').some((ext) => ext === fileNameParts[fileNameParts.length - 1]);
                                }
                            }
                        });
                        const accept = rules.fileAccept.map((e) => e.ext.split(',').map((ext) => e.type + '/' + ext));
                        if (!isValidType) {
                            return (
                                <span>
                                    Your file type <b>{filetMimeTypeParts[0]}</b> is not accepted! Please use any of: <b>{accept.join(', ')}</b> file type.
                                </span>
                            );
                        }
                        if (!isValidExt) {
                            return (
                                <span>
                                    Your file extension <b>{filetMimeTypeParts[1]}</b> is not accepted! Please use any of: <b>{accept.join(', ')}</b> file extension.
                                </span>
                            );
                        }
                    }
                    break;
                }
                case 'youtube':
                    if (!youtubeUrlParser(val)) {
                        return 'Not a valid youtube url';
                    }
                    break;
                case 'vimeo':
                    if (!vimeoUrlParser(val)) {
                        return 'Not a valid vimeo url';
                    }
                    break;
                case 'equalToField': //run only when submit the form, because current error  can't be updated after move to next field
                    if (this.state.isValidating && val !== this.state.values[rules.equalToField]) {
                        return <span>This field is not equal with next field value</span>;
                    }
                    break;
                case 'customFn':
                    return rules[ruleName](key, val, Object.assign({}, this.state.values)); // also send updated values
                case 'customAsyncFn':
                    // skip on this ones
                    break;
                default:
                    throw new Error(`this rule don't exist ruleName: '${ruleName}'`);
            }
        }
    }

    renderSubmit = () => {
        if (this.state.foundRenderSubmit || !this.props.showSubmit) return null;
        const { isSubmitting, isValidating, isSaved } = this.state;
        if (isSaved) {
            return (
                <div className="bottom-cta">
                    <button className="btn btnSaved" type="button">
                        <i className="far fa-check" />
                        Saved
                    </button>
                </div>
            );
        } else {
            return (
                <div className="bottom-cta">
                    <button
                        onClick={(e) => (this.props.tag !== 'form' ? this.handleSubmit(e) : '')}
                        disabled={isSubmitting || isValidating}
                        className={ClassNames('btn btn-trs', {
                            'btn-isLoading': isSubmitting || isValidating,
                        })}
                    >
                        {isSubmitting || (isValidating && <Spinner />)}
                        <i className="far fa-save" />
                        Save
                    </button>
                </div>
            );
        }
    };

    render() {
        const {
            tag: Tag,
            children,
            submitOnChange,
            // valuesKeyObject, //escape to send forward
            // renderSubmit,
            // initialValues,
            ...props
        } = this.props;
        // TODO this delete x, y approach is not the best; need to keep things tidy
        delete props['initialValues'];
        delete props['valuesKeyObject'];
        delete props['showSubmit'];
        // append class on submit
        if (submitOnChange && this.state.isSubmitting) {
            props.className += ' is-saving';
        }
        const propsRender = { children, submitOnChange, renderSubmit: this.renderSubmit, isSubmitting: this.state.isSubmitting };

        return (
            // <FieldsContext.Provider value={this.state,this.handleChange}> //this work only with '<FieldsContext.Consumer>' where you can extract part
            // when use 'this.context' as consumer you need the whole object so this.state will contain what we need to export
            <FieldsContext.Provider value={this.state}>
                {this.props.tag === '' ? (
                    <FieldsRender {...propsRender} />
                ) : (
                    <Tag {...props} onSubmit={this.handleSubmit}>
                        <FieldsRender {...propsRender} />
                    </Tag>
                )}
            </FieldsContext.Provider>
        );
        /*NOTE for mistake:
            {isValidation?
                <div>validation</div>
                :
                <Tag .../>
            }
            explain:  this will break the DOM when use uncontrolled forms
            example: a file will be saved in object 'values' when DOM is removed the ref to file from object 'values' will generate unhandled error
        */
    }
}

function FieldsRender({ children, submitOnChange, renderSubmit, isSubmitting }) {
    return (
        <>
            {children}
            {submitOnChange && isSubmitting && <Spinner />}
            {/*this.state.isValidating && //maybe a div with position absolute with overflow above form
                <div>validating ...</div>
            }
            {this.state.isSubmitting && //maybe a div with position absolute with overflow above form
                <div>isSubmitting ...</div>
            */}
            {!submitOnChange && renderSubmit()}
        </>
    );
}

Fields.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    submitOnChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
    valuesKeyObject: PropTypes.arrayOf(PropTypes.string), //initialValues are converted to simple values so added this props.valuesKeyObject to skip convert when don't need
    showSubmit: PropTypes.bool,
};
Fields.defaultProps = {
    tag: 'form',
    submitOnChange: false,
    initialValues: {},
    className: '',
    showSubmit: true,
};

function fileExtensionAccept({ file, fileExtensionAccept }) {
    const fileExt = file.name.split('.').pop();
    if (fileExtensionAccept.indexOf(fileExt) === -1) {
        return (
            <span>
                Your file extension <strong>{fileExt}</strong> is not accepted. Please try any of the following {fileExtensionAccept.join(', ')}.
            </span>
        );
    }
    return false;
}
