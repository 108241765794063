import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

function LocationAutocomplete(props) {
    const [address, setAddress] = React.useState(props.defaultValue);
    const [googleMapsHasLoaded, setGoogleMapsHasLoaded] = React.useState(typeof window.google !== 'undefined');

    const handleSelect = (address, placeId, suggestion) => {
        if (props.onDropdownSelect) props.onDropdownSelect(address, placeId);
        setAddress(address);
    };

    React.useEffect(() => {
        if (!googleMapsHasLoaded) {
            if (window.document.getElementById('location-autocomplete-library') === null) {
                let scriptTag = document.createElement('script');
                scriptTag.type = 'text/javascript';
                scriptTag.id = 'location-autocomplete-library';
                if (props.googleAPIKey) {
                    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${props.googleAPIKey}&libraries=places`;
                } else if (props.googlePlacesLibraryURL) {
                    scriptTag.src = props.googlePlacesLibraryURL;
                }
                scriptTag.addEventListener('load', () => {
                    setGoogleMapsHasLoaded(true);
                });

                (document.head || document.body).appendChild(scriptTag);
            } else {
                window.document.getElementById('location-autocomplete-library').addEventListener('load', () => {
                    setGoogleMapsHasLoaded(true);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (props.defaultValue && props.defaultValue !== address) setAddress(props.defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue]);

    return googleMapsHasLoaded ? (
        <PlacesAutocomplete value={address} onChange={(val) => setAddress(val)} onSelect={handleSelect} searchOptions={props.searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                    <div className="autocomplete-container">
                        <input
                            {...getInputProps({
                                placeholder: props.placeholder,
                                className: 'form-control',
                                autoComplete: 'new_password',
                            })}
                        />
                        {address.length > 0 && (
                            <button type="button" onClick={() => setAddress('')} className="clear-loc">
                                <i className="far fa-times" />
                            </button>
                        )}
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item suggestion-item--active' : 'suggestion-item';
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }}
        </PlacesAutocomplete>
    ) : null;
}

LocationAutocomplete.defaultProps = {
    placeholder: 'Choose Location',
    defaultValue: '',
    searchOptions: {}, //check documentation from `react-places-autocomplete`
};

LocationAutocomplete.propTypes = {
    onDropdownSelect: PropTypes.func,
    googleAPIKey: PropTypes.string,
    googlePlacesLibraryURL: PropTypes.string,
};
export default LocationAutocomplete;
