import React from 'react';
import PropTypes from 'prop-types';

MultipleCheckbox.propTypes = {
    RenderOnSelectedOption: PropTypes.func, //if need to  display additional data when the option is selected
    CustomOptionRender: PropTypes.func, // if need to change the default display of options
    CustomRender: PropTypes.func, // if need a complet new display, this will remove the need to use `RenderOnSelectedOption`or `CustomOptionRender`
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        })
    ).isRequired,
    value: PropTypes.array, // selected options
    keyLink: PropTypes.string, // will create an array of objects to be saved instead of array of ids
    onChange: PropTypes.func.isRequired, // update selected values
};
export default function MultipleCheckbox({ options, value, onChange, forwardRef, keyLink, dataSaved = [], RenderOnSelectedOption, CustomOptionRender, CustomRender }) {
    // a case when the component is unmount and mount inside <Fields /> and don't want to lose dataSaved if exist
    if (value === undefined && dataSaved.length > 0) {
        // value = dataSaved;
        onChange(dataSaved);
    }
    // const [dataSaved] = React.useState(value); //get the first data value before get changes by onChange, used in combination with keyLink
    const handleChange = (isChecked, val) => {
        let updateData = [];
        if (isChecked) {
            //do uncheck process
            if (keyLink === undefined) {
                updateData = value.filter((e) => e !== val);
            } else {
                updateData = value.filter((e) => e[keyLink] !== val);
            }
        } else {
            //do check process
            if (value !== undefined && value.length > 0) {
                updateData = value.filter(() => true); //clone array
            }
            if (keyLink === undefined) {
                updateData.push(val);
            } else {
                // push back the object from initial dataSaved if exist
                // dataSaved.filter(val2 => window.console.log(val2[keyLink], parseInt(val)));
                if (dataSaved.length > 0 && dataSaved.filter((val2) => val2[keyLink] === parseInt(val)).length === 1) {
                    updateData.push(dataSaved.filter((val2) => val2[keyLink] === val)[0]);
                } else {
                    updateData.push({
                        [keyLink]: val,
                    });
                }
            }
        }
        onChange(updateData);
    };
    return (
        <div ref={forwardRef}>
            {CustomRender === undefined ? (
                options.map((opt, index) => {
                    let isChecked = false;
                    if (value !== undefined) {
                        if (keyLink === undefined) {
                            isChecked = value.length > 0 && value.find((val2) => val2 === opt.value) ? true : false;
                        } else {
                            isChecked = value.length > 0 && value.find((val2) => val2[keyLink] === opt.value) ? true : false;
                        }
                    }
                    const DefaultRender = () =>
                        CustomOptionRender === undefined ? (
                            <div className={`span-checkbox ${isChecked ? 'checked' : ''}`}>
                                <span onClick={() => handleChange(isChecked, opt.value)} />
                                <label className="form-check-label" onClick={() => handleChange(isChecked, opt.value)}>
                                    {opt.label}
                                </label>
                            </div>
                        ) : (
                            <CustomOptionRender isChecked={isChecked} onClick={() => handleChange(isChecked, opt.value)} option={opt} />
                        );
                    if (isChecked && RenderOnSelectedOption !== undefined) {
                        return (
                            <React.Fragment key={index}>
                                <DefaultRender />
                                <RenderOnSelectedOption val={parseInt(opt.value)} />
                            </React.Fragment>
                        );
                    } else {
                        return <DefaultRender key={index} />;
                    }
                })
            ) : (
                <CustomRender options={options} value={value} handleChange={handleChange} keyLink={keyLink} forwardRef={forwardRef} />
            )}
        </div>
    );
}
