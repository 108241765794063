import produce from 'immer';

const subsidiaries = (state = [], action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'SUBSIDIARIES_RECEIVED':
                Object.assign(draft, action['payload']);
                break;
            case 'SUBSIDIARY_ADDED':
                draft.unshift(action.payload);
                break;
            case 'SUBSIDIARY_DELETED':
                draft.splice(
                    draft.findIndex((user) => user.id === action.payload),
                    1
                );
                break;
            // no default
        }
    });
export default subsidiaries;
